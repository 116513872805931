import type { User } from "@/entities/user";

type CurrencyInfo = {
  decimals: number;
  isCrypto: boolean;
  symbol: string;
};

type Transition = "fade" | "scale" | "slideDown" | "slideLeft" | "slideTop" | "slideRight";

export const currencies: Readonly<Record<User["currency"], CurrencyInfo>> = {
  AED: {
    decimals: 2,
    isCrypto: false,
    symbol: "DH",
  },
  AMD: {
    decimals: 2,
    isCrypto: false,
    symbol: "֏",
  },
  ARS: {
    decimals: 2,
    isCrypto: false,
    symbol: "$",
  },
  AUD: {
    decimals: 2,
    isCrypto: false,
    symbol: "$",
  },
  AZN: {
    decimals: 2,
    isCrypto: false,
    symbol: "₼",
  },
  BDT: {
    decimals: 2,
    isCrypto: false,
    symbol: "Tk",
  },
  BTC: {
    decimals: 8,
    isCrypto: true,
    symbol: "BTC",
  },
  BRL: {
    decimals: 2,
    isCrypto: false,
    symbol: "R$",
  },
  BYN: {
    decimals: 2,
    isCrypto: false,
    symbol: "Br",
  },
  CAD: {
    decimals: 2,
    isCrypto: false,
    symbol: "$",
  },
  CLP: {
    decimals: 2,
    isCrypto: false,
    symbol: "$",
  },
  COP: {
    decimals: 2,
    isCrypto: false,
    symbol: "$",
  },
  CRC: {
    decimals: 2,
    isCrypto: false,
    symbol: "₡",
  },
  CUP: {
    decimals: 2,
    isCrypto: false,
    symbol: "$MN",
  },
  CZK: {
    decimals: 2,
    isCrypto: false,
    symbol: "Kč",
  },
  DZD: {
    decimals: 2,
    isCrypto: false,
    symbol: "DA",
  },
  ETH: {
    decimals: 8,
    isCrypto: true,
    symbol: "ETH",
  },
  EUR: {
    decimals: 2,
    isCrypto: false,
    symbol: "€",
  },
  GEL: {
    decimals: 2,
    isCrypto: false,
    symbol: "₾",
  },
  GHS: {
    decimals: 2,
    isCrypto: false,
    symbol: "GH₵",
  },
  HKD: {
    decimals: 2,
    isCrypto: false,
    symbol: "HK$",
  },
  IDR: {
    decimals: 2,
    isCrypto: false,
    symbol: "Rp",
  },
  INR: {
    decimals: 2,
    isCrypto: false,
    symbol: "₹",
  },
  IQD: {
    decimals: 2,
    isCrypto: false,
    symbol: "IQD",
  },
  IRR: {
    decimals: 2,
    isCrypto: false,
    symbol: "IRR",
  },
  JOD: {
    decimals: 2,
    isCrypto: false,
    symbol: "JD",
  },
  KES: {
    decimals: 2,
    isCrypto: false,
    symbol: "KSh",
  },
  KGS: {
    decimals: 2,
    isCrypto: false,
    symbol: "с",
  },
  KRW: {
    decimals: 2,
    isCrypto: false,
    symbol: "₩",
  },
  KWD: {
    decimals: 2,
    isCrypto: false,
    symbol: "KD",
  },
  KZT: {
    decimals: 2,
    isCrypto: false,
    symbol: "₸",
  },
  LKR: {
    decimals: 2,
    isCrypto: false,
    symbol: "Rs",
  },
  LTC: {
    decimals: 7,
    isCrypto: true,
    symbol: "LTC",
  },
  MDL: {
    decimals: 2,
    isCrypto: false,
    symbol: "L",
  },
  MXN: {
    decimals: 2,
    isCrypto: false,
    symbol: "$",
  },
  MYR: {
    decimals: 2,
    isCrypto: false,
    symbol: "RM",
  },
  NGN: {
    decimals: 2,
    isCrypto: false,
    symbol: "₦",
  },
  NPR: {
    decimals: 2,
    isCrypto: false,
    symbol: "Re",
  },
  OMR: {
    decimals: 2,
    isCrypto: false,
    symbol: "OMR",
  },
  PAB: {
    decimals: 2,
    isCrypto: false,
    symbol: "B",
  },
  PEN: {
    decimals: 2,
    isCrypto: false,
    symbol: "S/",
  },
  PHP: {
    decimals: 2,
    isCrypto: false,
    symbol: "PhP",
  },
  PKR: {
    decimals: 2,
    isCrypto: false,
    symbol: "Rs",
  },
  PLN: {
    decimals: 2,
    isCrypto: false,
    symbol: "Zł",
  },
  QAR: {
    decimals: 2,
    isCrypto: false,
    symbol: "QR",
  },
  RUB: {
    decimals: 2,
    isCrypto: false,
    symbol: "₽",
  },
  RWF: {
    decimals: 2,
    isCrypto: false,
    symbol: "FRw",
  },
  SEK: {
    decimals: 2,
    isCrypto: false,
    symbol: "kr",
  },
  SGD: {
    decimals: 2,
    isCrypto: false,
    symbol: "S$",
  },
  SOS: {
    decimals: 2,
    isCrypto: false,
    symbol: "So",
  },
  THB: {
    decimals: 2,
    isCrypto: false,
    symbol: "฿",
  },
  TJS: {
    decimals: 2,
    isCrypto: false,
    symbol: "С",
  },
  TRX: {
    decimals: 2,
    isCrypto: true,
    symbol: "TRX",
  },
  TRY: {
    decimals: 2,
    isCrypto: false,
    symbol: "₺",
  },
  TZS: {
    decimals: 2,
    isCrypto: false,
    symbol: "TSh",
  },
  UAH: {
    decimals: 2,
    isCrypto: false,
    symbol: "₴",
  },
  UGX: {
    decimals: 2,
    isCrypto: false,
    symbol: "Ush",
  },
  USD: {
    decimals: 2,
    isCrypto: false,
    symbol: "$",
  },
  USDT: {
    decimals: 2,
    isCrypto: true,
    symbol: "USDT",
  },
  UZS: {
    decimals: 2,
    isCrypto: false,
    symbol: "S",
  },
  VND: {
    decimals: 2,
    isCrypto: false,
    symbol: "₫",
  },
  XAF: {
    decimals: 2,
    isCrypto: false,
    symbol: "₣",
  },
  XOF: {
    decimals: 2,
    isCrypto: false,
    symbol: "₣",
  },
  ZAR: {
    decimals: 2,
    isCrypto: false,
    symbol: "R",
  },
  ZMW: {
    decimals: 2,
    isCrypto: false,
    symbol: "ZK",
  },
};

export const cryptocurrencies = (Object.keys(currencies) as User["currency"][]).filter(
  (currency) => currencies[currency].isCrypto,
);

export const deviceTypes: Partial<Record<User["platform"], string>> = {
  android: "native-android",
  ios: "native-ios",
};

export const transitions: Readonly<Record<Transition, string>> = {
  fade: "fade",
  scale: "scale",
  slideDown: "slide-down",
  slideLeft: "slide-left",
  slideTop: "slide-top",
  slideRight: "slide-right",
};

export const routeNames: Readonly<Dictionary<string>> = {
  emptyPage: "Empty",
  errorPage: "Error",
  depositBasePage: "Base deposit",
  depositCompletePage: "Complete deposit",
  depositCryptoAddressPage: "Crypto address",
  depositCryptoCoinPage: "Crypto coin",
  depositCryptoNetworkPage: "Crypto network",
  depositCustomEazypePage: "Eazype deposit",
  depositCustomUpiQrPage: "Upi QR deposit",
  depositGroupPage: "Group deposit",
  depositIndiaBasePage: "Base indian deposit",
  depositIndiaPaymentPage: "Indian payment",
  depositIndiaPage: "Indian deposit",
  depositListPage: "Deposit",
  withdrawalBasePage: "Base withdrawal",
  withdrawalConfirmPage: "Confirm withdrawal",
  withdrawalCompletePage: "Complete withdrawal",
  withdrawalGroupPage: "Group withdrawal",
  withdrawalListPage: "Withdrawal",
  withdrawalPreparePage: "Withdrawal prepare",
};

export const routeNamesV2: Readonly<Dictionary<string>> = {
  emptyPage: "Empty v2",
  errorPage: "Error v2",
  depositCompletePage: "Complete deposit v2",
  depositCryptoAddressPage: "Crypto address v2",
  depositCryptoListPage: "Crypto list v2",
  depositGroupBasePage: "Group base deposit v2",
  depositGroupListPage: "Group list deposit v2",
  depositGroupPage: "Group deposit v2",
  depositHostToHostPage: "Deposit host to host v2",
  depositListPage: "Deposit v2",
  depositPeerToPeerInstructionPage: "Deposit peer to peer instruction v2",
  depositPeerToPeerInvoicePage: "Deposit peer to peer invoice v2",
  depositPeerToPeerListPage: "Deposit peer to peer list v2",
  depositPeerToPeerPage: "Deposit peer to peer v2",
  depositRedirectPage: "Redirect deposit v2",
  depositRedirectInstructionPage: "Redirect deposit instruction v2",
  withdrawalCompletePage: "Complete withdrawal v2",
  withdrawalConfirmPage: "Confirm withdrawal v2",
  withdrawalGroupBasePage: "Group base withdrawal v2",
  withdrawalGroupListPage: "Group list withdrawal v2",
  withdrawalGroupPage: "Group withdrawal v2",
  withdrawalListPage: "Withdrawal v2",
  withdrawalNigerianBanksPage: "Withdrawal nigerian banks v2",
  withdrawalRedirectPage: "Redirect withdrawal v2",
  withdrawalRedirectInstructionPage: "Redirect withdrawal instruction v2",
};
