import type { DepositCreateResponse, DepositStatus, PeerToPeerCard } from "@/entities/peer-to-peer";

export const confirmationIdFieldRegex = "^\\d{8,10}$";

export const defaultResponse: DepositCreateResponse = {
  card: {
    payment_system: "card-p2p",
  },
  created: new Date().toISOString(),
  depositId: 0,
  fields: null,
  passedAmount: 0,
  passedCurrency: "MXN",
  payment_amount: "0",
  payment_currency: "MXN",
  timeout_minutes: 0,
};

export const depositCancelStatuses: Readonly<DepositStatus[]> = ["AUTO_CANCELED", "CANCELED", "PAYED"];

export const internationalTransferCountries: Readonly<PeerToPeerCard["country_code"][]> = [
  "az",
  "kg",
  "kz",
  "tj",
  "uz",
];

export const paymentMethodsWithCancelButton: Readonly<string[]> = ["card-1win-p2p"];

export const paymentMethodsWithConfirmationIdField: Readonly<string[]> = ["bkash_p2p", "nagad_p2p"];

export const paymentMethodsWithGuarantee: Readonly<string[]> = ["card_p2p_uz_uzcard", "card_p2p_uz_humo"];

export const paymentMethodsWithSupport: Readonly<string[]> = [
  "card_p2p_kg_el_cart",
  "card_p2p_kg_demir_bank",
  "card_p2p_kg_optima_bank",
  "card_p2p_kg_halyk_bank",
  "card_p2p_kg_bakai_bank",
  "card_p2p_kg_m_bank",
  "card_p2p_kg_kicb_bank",
  "card_p2p_kg_bai_tushum_bank",
  "card_p2p_kg_keremet_bank",
  "card_p2p_kg_kompanion_bank",
  "card_p2p_kg_dcb_360_bank",
];
