<script setup lang="ts">
import { computed } from "vue";

import type { PaymentGroup } from "@/entities/payment-method";
import { PaymentMethodGroupIcons, PaymentMethodIcon, circlePaymentIconsByGroup } from "@/entities/payment-method";
import type { Transaction } from "@/entities/transaction";
import { Caption, Card } from "@/shared/ui-v2";

type Emits = {
  change: [paymentGroup: PaymentGroup];
};

interface Props {
  paymentGroup: PaymentGroup;
  transaction: Transaction;
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const circleIcons = computed(() => circlePaymentIconsByGroup[props.paymentGroup.name] ?? []);

const onChange = () => emit("change", props.paymentGroup);
</script>

<template>
  <Card
    :class="$style.root"
    is-pressable
    @click="onChange"
  >
    <PaymentMethodGroupIcons
      v-if="circleIcons.length"
      :icons="circleIcons"
      :payment-group="paymentGroup"
      :transaction="transaction"
    />
    <PaymentMethodIcon
      v-else
      :name="paymentGroup.name"
      size="l"
      :transaction="transaction"
      type="base"
    />
    <Caption
      :class="$style.label"
      size="s-sb"
    >
      {{ paymentGroup.label }}
    </Caption>
  </Card>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-8);
  text-align: start;
}

.label {
  @add-mixin text-ellipsis;

  width: 100%;
}
</style>
