<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { IconBox, Input } from "@/shared/ui-v2";

type Emits = {
  input: [value: string];
};

interface Props {
  value: string;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const { t } = useI18n();

const onInput = (value: string) => emit("input", value);
</script>

<template>
  <Input
    is-clearable
    :placeholder="t('search.placeholder')"
    :value="value"
    @clear="onInput('')"
    @input="(event) => onInput((event.target as HTMLInputElement).value)"
  >
    <template #contentLeft>
      <IconBox
        color="secondary"
        name="magnify-2"
        size="s"
      />
    </template>
  </Input>
</template>
