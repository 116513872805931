<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import type { Limits } from "@/entities/payment-method";
import type { User } from "@/entities/user";
import { NUMBER_WITH_COMMAS_REGEX, useFormStore } from "@/features/form";
import { formatNumber, getCurrencyFractionDigits } from "@/shared/lib";
import { Input } from "@/shared/ui-v2";

type Emits = {
  focus: [];
  input: [value: string];
};

interface Props {
  currency: User["currency"];
  limits: Limits;
  value: string;
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const validators = {
  isLeadingDot: (value: string) => value === ".",
  isNonDecimalLeadingZero: (value: string) => {
    const [first, second] = value;
    return first === "0" && value.length > 1 && second !== ".";
  },
  isInvalidOrExceedsLimit: (value: string) => {
    const isValid = (value: string) => {
      const max = getCurrencyFractionDigits(props.currency);
      return new RegExp(`^(\\d+((,\\d+)?)+)?(\\.\\d{0,${max}})?$`).test(value);
    };

    const numericValue = value.replace(NUMBER_WITH_COMMAS_REGEX, "");
    const minValueLength = `${Math.floor(+numericValue)}`.length;
    const maxDigitsLength = `${Math.floor(props.limits.max)}`.length + 1;

    return !isValid(value) || minValueLength > maxDigitsLength;
  },
};

const { t } = useI18n();

const { isAmountValid } = storeToRefs(useFormStore());

const errorText = computed(() => {
  const [min, max] = modifiedLimits.value;

  if (+props.value < props.limits.min) {
    return t("form.amountInput.limit.min", { amount: min });
  }

  if (+props.value > props.limits.max) {
    return t("form.amountInput.limit.max", { amount: max });
  }

  return "";
});

const hintMessage = computed(() => {
  const [min, max] = modifiedLimits.value;

  return t("form.amountInput.hint", { from: min, to: max });
});

const modifiedAmount = computed(() => {
  if (!props.value) {
    return "";
  }

  const [, fractionalPart = ""] = props.value.split(".");
  const formattedValue = formatNumber(
    {
      currency: props.currency,
      style: "decimal",
      minimumFractionDigits: fractionalPart.length,
    },
    +props.value,
  );

  return props.value.endsWith(".") ? formattedValue + "." : formattedValue;
});

const modifiedLimits = computed(() =>
  [props.limits.min, props.limits.max].map((limit) => formatNumber({ currency: props.currency }, limit)),
);

const onFocus = () => emit("focus");
const onInput = (value: string) => emit("input", value);

const handleInput = (event: Event) => {
  const value = (event as Event & { target: HTMLInputElement }).target.value;

  if (
    validators.isLeadingDot(value) ||
    validators.isNonDecimalLeadingZero(value) ||
    validators.isInvalidOrExceedsLimit(value)
  ) {
    const slicedValue = value.slice(0, value.length - 1);
    const replacedValue = slicedValue.replace(NUMBER_WITH_COMMAS_REGEX, "");
    // eslint-disable-next-line no-param-reassign
    (event as Event & { target: HTMLInputElement }).target.value = slicedValue;
    onInput(replacedValue);
    return;
  }

  onInput(value.replace(NUMBER_WITH_COMMAS_REGEX, ""));
};
</script>

<template>
  <Input
    :hint-message="hintMessage"
    input-mode="numeric"
    :label="t('form.amountInput.title')"
    :validations="[
      {
        isExists: !isAmountValid,
        message: errorText,
        regex: '*',
      },
    ]"
    :value="modifiedAmount"
    @focus="onFocus"
    @input="handleInput"
  />
</template>
