<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";

import { PaymentHeader, useAnnotation, usePaymentMethodStore } from "@/entities/payment-method";
import { SupportButton, usePeerToPeerStore } from "@/entities/peer-to-peer";
import { SectionSpinnerV2, SectionWrapperV2 } from "@/entities/section";
import type { Snippet } from "@/entities/user";
import { useUserStore } from "@/entities/user";
import { SubmitButtonV2, getAmount, getRandomAmount, useFormStore } from "@/features/form";
import { isCryptocurrency } from "@/shared/lib";
import { ScrollShadow } from "@/shared/ui";
import { Alert } from "@/shared/ui-v2";
import { DepositFormV2 } from "@/widgets/deposit";

const { t } = useI18n();

const { limits, paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const { snippet, user } = storeToRefs(useUserStore());

const { changeAmount } = useFormStore();

const peerToPeerStore = usePeerToPeerStore();
const { isCheckingDeposit, hasSupportButton, isCreatingDeposit, isFetchingBank, isFetchingCaptcha } =
  storeToRefs(peerToPeerStore);
const { handleCheckDeposit, handleCreateDeposit } = peerToPeerStore;

const { annotation } = useAnnotation({
  paymentMethod,
  transaction: "deposit",
});

onBeforeMount(async () => {
  const amount = isCryptocurrency(user.value.currency)
    ? getAmount(snippet.value as Snippet, limits.value)
    : getRandomAmount(snippet.value as Snippet, limits.value);
  changeAmount(amount);
  await handleCheckDeposit();
});
</script>

<template>
  <SectionWrapperV2>
    <ScrollShadow :class="$style.container">
      <PaymentHeader
        :class="$style.space"
        :payment-method="paymentMethod"
        transaction="deposit"
      >
        {{ paymentLabel }}
      </PaymentHeader>
      <SectionSpinnerV2 v-if="isCheckingDeposit" />
      <template v-else>
        <Alert
          v-if="annotation"
          type="warning"
        >
          {{ annotation }}
        </Alert>
        <DepositFormV2 :class="$style.space" />
      </template>
    </ScrollShadow>
    <div
      v-if="!isCheckingDeposit"
      :class="[$style.buttons, $style.space]"
    >
      <SubmitButtonV2
        :class="$style.button"
        color="accent"
        :is-loading="isCreatingDeposit || isFetchingBank || isFetchingCaptcha"
        @click="handleCreateDeposit"
      >
        {{ t("deposit.button") }}
      </SubmitButtonV2>
      <SupportButton v-if="hasSupportButton" />
    </div>
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.space {
  margin: var(--spacing-0) var(--spacing-4);
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-16);
}

.button {
  width: 100%;
}
</style>
