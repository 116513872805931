import { storeToRefs } from "pinia";

import { useThemeStore } from "@/entities/theme";
import { useUserStore } from "@/entities/user";
import { appStorage, bridgeService } from "@/shared/services";

export const waitForProperty = <T>(
  property: "billingUserData",
  timeout: number,
  callback: (value: T) => void,
): Promise<void> => {
  const INTERVAL_TIME = 250;

  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (property in window) {
        clearInterval(interval);
        callback(window[property] as T);
        resolve();
      }
    }, INTERVAL_TIME);

    setTimeout(() => {
      clearInterval(interval);
      reject("Can't find a property in window");
    }, timeout);
  });
};

export const initApp = async () => {
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);
  const { changeUser } = userStore;

  const { isLegacyDesign } = storeToRefs(useThemeStore());

  const mobileBridge = window.billingBridge || window.webkit;

  if (mobileBridge) {
    await waitForProperty("billingUserData", 5_000, changeUser);
  } else {
    const userFromStorage = appStorage.getUser();
    changeUser({
      ...user.value,
      ...(userFromStorage ?? {}),
    });
  }

  return {
    isLegacyDesign: isLegacyDesign.value,
    user: user.value,
  };
};

export const changeLocation = (href: string) => {
  window.location.href = href;
};

export const safeJsonParse = (value: string) => {
  try {
    return [null, JSON.parse(value)];
  } catch (err) {
    return [err];
  }
};

export const copyToClipboard = async (copiedMessage: string) => {
  const { isNativePlatform } = storeToRefs(useUserStore());

  if (isNativePlatform.value) {
    bridgeService.notifyNative({ messageType: "onCopyToBuffer", copiedMessage });
    return;
  }

  await navigator.clipboard.writeText(copiedMessage);
};
