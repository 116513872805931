<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";

import type { PaymentGroup, PaymentMethod } from "@/entities/payment-method";
import { basePathsV2, cardViews, groupPathsV2, usePaymentMethodStore } from "@/entities/payment-method";
import { defaultResponse, usePeerToPeerStore } from "@/entities/peer-to-peer";
import { SectionWrapperV2 } from "@/entities/section";
import type { Snippet } from "@/entities/user";
import { useUserStore } from "@/entities/user";
import { getAmount, useFormStore } from "@/features/form";
import { routeNamesV2 } from "@/shared/constants";
import { PaymentSystemsV2 } from "@/widgets/transaction";

const router = useRouter();

const paymentMethodStore = usePaymentMethodStore();
const { initialPaymentMethods, paymentGroup, paymentMethod, limits, filteredPaymentGroups, filteredPaymentMethods } =
  storeToRefs(paymentMethodStore);
const { changePaymentGroup, changePaymentMethod, changePaymentMethods } = paymentMethodStore;

const { changeResponse } = usePeerToPeerStore();

const { snippet } = storeToRefs(useUserStore());

const { changeAmount, changeAmountValidity, changeFields, setFields } = useFormStore();

const resetData = () => {
  changeAmount("");
  changeAmountValidity(true);
  changePaymentGroup(null);
  changePaymentMethod(null);
  changePaymentMethods(initialPaymentMethods.value);
  changeResponse(defaultResponse);
  setFields([]);
};

const selectPaymentGroup = async (paymentGroup: PaymentGroup) => {
  changePaymentGroup(paymentGroup);
  changePaymentMethods(Object.values(paymentGroup.paymentMethods));
  await router.push({
    name: groupPathsV2[paymentGroup.name] ?? routeNamesV2.depositGroupListPage,
  });
};

const selectPaymentMethod = async (paymentMethod: PaymentMethod) => {
  changePaymentMethod(paymentMethod);
  changeFields(paymentMethod.fields);
  const amount = getAmount(snippet.value as Snippet, limits.value);
  changeAmount(amount);
  await router.push({
    name: basePathsV2[paymentMethod.cardViewType ?? cardViews.default] ?? routeNamesV2.depositRedirectPage,
  });
};

onBeforeMount(resetData);
</script>

<template>
  <SectionWrapperV2 :class="$style.root">
    <PaymentSystemsV2
      :payment-group="paymentGroup"
      :payment-groups="filteredPaymentGroups"
      :payment-method="paymentMethod"
      :payment-methods="filteredPaymentMethods"
      transaction="deposit"
      @select-payment-group="selectPaymentGroup"
      @select-payment-method="selectPaymentMethod"
    />
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.root {
  @add-mixin scrollbar-hidden;

  overflow-y: auto;
}
</style>
