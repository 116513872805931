import io from "socket.io-client";
import type { App } from "vue";

import type { User } from "@/entities/user";
import { deviceTypes } from "@/shared/constants";

interface Options {
  user: User;
}

const socket = io(import.meta.env.VITE_SERVER_HOST, {
  autoConnect: false,
  path: import.meta.env.VITE_SOCKET_PATH,
  reconnection: false,
  transports: ["websocket"],
});

export default {
  install: (_: App, options: Options) => {
    const deviceType = deviceTypes[options.user.platform];

    socket.io.opts.query = {
      Authorization: options.user.token,
      Language: options.user.lang,
      xorigin: new URL(import.meta.env.VITE_SERVER_HOST).host,
      ...(deviceType && {
        deviceType,
        isNative: true,
      }),
    };

    socket.connect();
  },
};

export { socket };
