<script setup lang="ts">
import type { CssClass } from "@/shared/ui-v2";

interface Props {
  color?: "informative" | "inherit" | "negative" | "primary" | "secondary" | "tertiary" | "warning";
  size?: "xs" | "s" | "s-sb";
}

interface Slots {
  default(props: object): unknown;
}

withDefaults(defineProps<Props>(), {
  color: "inherit",
  size: "s",
});

defineSlots<Slots>();

const colors: Record<NotUndefined<Props["color"]>, CssClass> = {
  informative: "color-informative",
  inherit: "",
  negative: "color-negative",
  primary: "color-primary",
  secondary: "color-secondary",
  tertiary: "color-tertiary",
  warning: "color-warning",
};

const sizes: Record<NotUndefined<Props["size"]>, CssClass> = {
  xs: "size-xs",
  s: "size-s",
  "s-sb": "size-s-sb",
};
</script>

<template>
  <span :class="[$style[colors[color]], $style[sizes[size]]]">
    <slot />
  </span>
</template>

<style module lang="postcss">
.color-informative {
  color: var(--informative);
}

.color-negative {
  color: var(--negative);
}

.color-primary {
  color: var(--fg-primary);
}

.color-secondary {
  color: var(--fg-secondary);
}

.color-tertiary {
  color: var(--fg-tertiary);
}

.color-warning {
  color: var(--warning);
}

.size-xs {
  @add-mixin caption-xs;
}

.size-s {
  @add-mixin caption-s-regular;
}

.size-s-sb {
  @add-mixin caption-s-semibold;
}
</style>
