<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useI18n } from "vue-i18n";

import { PaymentHeader, usePaymentMethodStore } from "@/entities/payment-method";
import type { DecryptedCard } from "@/entities/peer-to-peer";
import {
  ConfirmationId,
  SupportButton,
  getDecryptedCard,
  useConfirmationId,
  usePeerToPeerStore,
  confirmationIdFieldRegex,
} from "@/entities/peer-to-peer";
import { SectionWrapperV2 } from "@/entities/section";
import { useThemeStore } from "@/entities/theme";
import { useUserStore } from "@/entities/user";
import { ScrollShadow } from "@/shared/ui";
import { Alert, Button, Countdown } from "@/shared/ui-v2";
import { Invoice } from "@/widgets/peer-to-peer";

const { t } = useI18n();

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const {
  confirmationId,
  isConfirmationIdValid,
  hasConfirmationId,
  changeConfirmationIdValidity,
  handleChangeConfirmationId,
} = useConfirmationId({
  paymentMethod,
});

const peerToPeerStore = usePeerToPeerStore();
const {
  bank,
  depositCompleteData,
  response,
  hasCancelButton,
  hasSupportButton,
  isCancellingDeposit,
  isCompletingDeposit,
  isDepositCancelled,
  isGuaranteed,
} = storeToRefs(peerToPeerStore);
const { handleCancelDeposit, handleCompleteDeposit, handleFetchDepositStatus } = peerToPeerStore;

const { user } = storeToRefs(useUserStore());

const { theme } = storeToRefs(useThemeStore());

const decryptedCard = ref();
const interval = ref<ReturnType<typeof setInterval>>();

const annotation = computed(() => {
  if (hasConfirmationId.value) {
    return t("peerToPeer.annotation.warning.confirmationId");
  }

  if (response.value.card.bank_account) {
    return t("peerToPeer.annotation.warning.bankAccount");
  }

  return t("peerToPeer.annotation.warning.cardNumber");
});

const countdownDuration = computed(() => {
  const { created, timeout_minutes } = response.value;

  const time = new Date(created).getTime();
  const timeout = timeout_minutes * 1_000 * 60;

  return time + timeout - Date.now();
});

const changeDecryptedCard = (data: DecryptedCard | undefined) => {
  decryptedCard.value = data;
};

const changeInterval = (value: ReturnType<typeof setInterval>) => {
  interval.value = value;
};

const cancelDeposit = async () => {
  await handleCancelDeposit();
  clearInterval(interval.value);
};

const completeDeposit = async () => {
  changeConfirmationIdValidity(new RegExp(confirmationIdFieldRegex, "u").test(confirmationId.value));

  if (hasConfirmationId.value && !isConfirmationIdValid.value) {
    return;
  }

  await handleCompleteDeposit({
    ...(hasConfirmationId.value && { confirmationId: confirmationId.value }),
  });
};

onBeforeMount(async () => {
  changeDecryptedCard(
    await getDecryptedCard(response.value.card, {
      platform: user.value.platform,
      theme: theme.value,
    }),
  );
  changeInterval(
    setInterval(async () => {
      await handleFetchDepositStatus();

      if (isDepositCancelled.value) {
        await handleCompleteDeposit();
        clearInterval(interval.value);
      }
    }, 5_000),
  );
});

onBeforeUnmount(() => clearInterval(interval.value));
</script>

<template>
  <SectionWrapperV2>
    <ScrollShadow :class="$style.container">
      <PaymentHeader
        :class="$style.space"
        :payment-method="paymentMethod"
        transaction="deposit"
      >
        {{ paymentLabel }}
      </PaymentHeader>
      <Alert
        v-if="isGuaranteed"
        :class="$style.space"
        type="success"
      >
        <span v-sanitize-html="t('peerToPeer.guarantee')" />
      </Alert>
      <Invoice
        :bank="bank"
        :class="$style.space"
        :decrypted-card="decryptedCard"
        :has-confirmation-id="hasConfirmationId"
        :response="response"
      />
    </ScrollShadow>
    <ConfirmationId
      v-if="hasConfirmationId"
      :class="$style.space"
      :is-valid="isConfirmationIdValid"
      :value="confirmationId"
      @input="handleChangeConfirmationId"
    />
    <SupportButton
      v-if="hasSupportButton"
      :class="[$style.supportButton, $style.space]"
    />
    <Alert
      :class="$style.space"
      type="warning"
    >
      {{ annotation }}
    </Alert>
    <Countdown
      :class="$style.space"
      :duration="countdownDuration"
      @expire="cancelDeposit"
    />
    <div
      v-if="!depositCompleteData?.cancelled"
      :class="$style.buttons"
    >
      <Button
        color="accent"
        :is-disabled="isCancellingDeposit || (hasConfirmationId && !isConfirmationIdValid)"
        :is-loading="isCompletingDeposit"
        @click="completeDeposit"
      >
        {{ t("button.confirm") }}
      </Button>
      <Button
        v-if="hasCancelButton"
        color="neutral"
        :is-disabled="isCompletingDeposit"
        :is-loading="isCancellingDeposit"
        @click="cancelDeposit"
      >
        {{ t("button.cancel") }}
      </Button>
    </div>
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.space {
  margin: var(--spacing-0) var(--spacing-4);
}

.supportButton {
  justify-content: flex-start;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  margin: auto var(--spacing-4) var(--spacing-4);
}
</style>
