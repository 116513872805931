import { omit } from "lodash";

import type { User } from "@/entities/user";
import { getCurrencyFractionDigits, isCryptocurrency } from "@/shared/lib";

export type FormatOptions = Omit<Intl.NumberFormatOptions, "currency"> & { currency: User["currency"] };

const LOCALE = "es-MX";
const STUB_CURRENCY = "XTS";

const formatFiat = (options: FormatOptions, value: number) => {
  const fiatOptions = {
    currencyDisplay: "narrowSymbol",
    style: "currency",
    minimumFractionDigits: 0,
    maximumFractionDigits: getCurrencyFractionDigits(options.currency),
  };

  return new Intl.NumberFormat(LOCALE, { ...fiatOptions, ...options }).format(value);
};

const formatCrypto = (options: FormatOptions, value: number) => {
  const cryptoOptions = {
    currency: STUB_CURRENCY,
    currencyDisplay: "code",
    style: "currency",
    minimumFractionDigits: 0,
    maximumFractionDigits: getCurrencyFractionDigits(options.currency),
  };

  return new Intl.NumberFormat(LOCALE, { ...cryptoOptions, ...omit(options, "currency") })
    .format(value)
    .replace(STUB_CURRENCY, options.currency);
};

export const formatNumber = (options: FormatOptions, value: number): string => {
  if (isCryptocurrency(options.currency)) {
    return formatCrypto(options, value);
  }

  return formatFiat(options, value);
};
