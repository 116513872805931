<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount, onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { PaymentHeader, usePaymentMethodStore } from "@/entities/payment-method";
import { SectionButtonV2, SectionWrapperV2 } from "@/entities/section";
import { WithdrawalConfirmationAmountV2, confirmWithdrawal, useWithdrawalStore } from "@/entities/withdrawal";
import { useRequest } from "@/shared/composables";
import { routeNamesV2 } from "@/shared/constants";
import { WithdrawalConfirmationCodeV2 } from "@/widgets/withdrawal";

const CODE_LENGTH = 7;
const RESEND_INTERVAL_IN_SECONDS = 45;

const { t } = useI18n();
const router = useRouter();

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const withdrawalStore = useWithdrawalStore();
const { confirmationData, isLoadingWithdrawal } = storeToRefs(withdrawalStore);
const { onSubmit } = withdrawalStore;

const { isLoading: isLoadingWithdrawalConfirmation, execute: confirmWithdrawalRequest } = useRequest(() =>
  confirmWithdrawal({
    code: code.value.join(""),
  }),
);

const error = reactive({
  isExists: false,
  message: t("withdrawal.confirm.errorText"),
});

const code = ref(Array(CODE_LENGTH).fill("") as string[]);
const interval = ref<ReturnType<typeof setInterval>>();
const isSubmitButtonVisible = ref(false);
const time = ref(0);

const formattedTime = computed(() => {
  const minutes = Math.floor(time.value / 60);
  const seconds = time.value % 60;

  const formatter = new Intl.DateTimeFormat("default", {
    minute: "2-digit",
    second: "2-digit",
  });

  return formatter.format(new Date(0, 0, 0, 0, minutes, seconds));
});

const changeCode = (value: string[]) => {
  code.value = value;
};

const changeCodeItemByIndex = (index: number, value: string) => {
  code.value[index] = value;
};

const changeError = (value: boolean) => {
  error.isExists = value;
};

const changeInterval = (value: ReturnType<typeof setInterval>) => {
  interval.value = value;
};

const changeIsSubmitButtonVisible = (value: boolean) => {
  isSubmitButtonVisible.value = value;
};

const changeTime = (value: number) => {
  time.value = value;
};

const handleResend = () => {
  changeCode(Array(CODE_LENGTH).fill(""));
  initInterval();
  onSubmit();
};

const initInterval = () => {
  changeTime(RESEND_INTERVAL_IN_SECONDS);
  changeIsSubmitButtonVisible(false);
  changeInterval(
    setInterval(() => {
      if (time.value <= 1) {
        changeIsSubmitButtonVisible(true);
        clearInterval(interval.value);
      }

      time.value -= 1;
    }, 1_000),
  );
};

const submit = async () => {
  try {
    changeError(false);
    await confirmWithdrawalRequest();
    await router.push({ name: routeNamesV2.withdrawalCompletePage });
  } catch (e) {
    changeError(true);
    throw new Error((e as Error).message);
  }
};

onBeforeUnmount(() => clearInterval(interval.value));
onMounted(initInterval);
</script>

<template>
  <SectionWrapperV2>
    <PaymentHeader
      :payment-method="paymentMethod"
      transaction="withdrawal"
    >
      {{ paymentLabel }}
    </PaymentHeader>
    <div :class="$style.container">
      <WithdrawalConfirmationAmountV2 />
      <WithdrawalConfirmationCodeV2
        :code="code"
        :confirmation-data="confirmationData"
        :count="CODE_LENGTH"
        :error="error"
        :formatted-time="formattedTime"
        :is-submit-button-visible="isSubmitButtonVisible"
        @change-code="changeCode"
        @change-code-by-index="changeCodeItemByIndex"
        @change-error="changeError"
        @resend-code="handleResend"
      />
    </div>
    <SectionButtonV2
      :is-loading="isLoadingWithdrawal || isLoadingWithdrawalConfirmation"
      @click="submit"
    >
      {{ t("withdrawal.button") }}
    </SectionButtonV2>
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
</style>
