<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import type { PrepareDepositDetail } from "@/entities/deposit";
import { useDepositStore } from "@/entities/deposit";
import { useNotificationStore } from "@/features/notification";
import { bridgeService } from "@/shared/services";
import { Divider, Icon, Snippet, Typography } from "@/shared/ui";
import { getDetailIcon, isCopiedDetail } from "@/widgets/india";

const { t } = useI18n();

const { details } = storeToRefs(useDepositStore());

const { createNotification } = useNotificationStore();

const onCopy = (detail: PrepareDepositDetail) => {
  createNotification({
    color: "auto",
    content: t("deposit.india.copyDetails", { detail: detail.name }),
    customClass: "bg-gray-500 dark:bg-oxford",
  });
  bridgeService.notifyNative({
    copiedMessage: detail.value,
    messageType: "onCopyToBuffer",
  });
};
</script>

<template>
  <div class="flex flex-col gap-3">
    <Snippet
      v-for="detail of details"
      :key="detail.name"
      :hide-copy-button="!isCopiedDetail(detail)"
      @copy="onCopy(detail)"
    >
      <template #default>
        <Typography
          class="text-gray-350 dark:text-pearl"
          size="xs"
        >
          {{ detail.name }}
        </Typography>
        {{ detail.value }}
      </template>
      <template #start-content>
        <div
          v-if="getDetailIcon(detail)"
          class="flex gap-3"
        >
          <Icon
            class="h-5 w-5"
            :name="getDetailIcon(detail)"
          />
          <Divider
            class="bg-gray-900 dark:bg-anchor"
            orientation="vertical"
          />
        </div>
      </template>
    </Snippet>
  </div>
</template>
