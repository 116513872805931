<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";

import type { InternationalTransferBank } from "@/entities/peer-to-peer";
import { useInternationalTransferInstruction } from "@/entities/peer-to-peer";
import { useUserStore } from "@/entities/user";
import { useFormStore } from "@/features/form";
import { InstructionPageV2 } from "@/pages";
import { formatNumber } from "@/shared/lib";

const route = useRoute();

const { user } = storeToRefs(useUserStore());

const { amount } = storeToRefs(useFormStore());

const { instructionsByBank } = useInternationalTransferInstruction({
  amount: formatNumber({ currency: user.value.currency }, +amount.value),
});

const content = computed(() => instructionsByBank.value[route.query.bank as InternationalTransferBank]);
</script>

<template>
  <InstructionPageV2 :content="content" />
</template>
