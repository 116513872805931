<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { QrCode } from "@/entities/qr-code";
import { Paragraph } from "@/shared/ui-v2";

interface Props {
  address: string;
}

defineProps<Props>();

const { t } = useI18n();

const qrCodeOptions = {
  cornersDotOptions: {
    type: "square",
  },
  cornersSquareOptions: {
    type: "square",
  },
  dotsOptions: {
    type: "square",
  },
  width: 200,
  height: 200,
};
</script>

<template>
  <div :class="$style.root">
    <QrCode
      :class="$style.qr"
      :options="qrCodeOptions"
      :text="address"
    />
    <div :class="$style.container">
      <Paragraph
        color="primary"
        size="l-sb"
      >
        {{ t("deposit.crypto.address.text") }}
      </Paragraph>
      <Paragraph
        :class="$style.address"
        color="tertiary"
      >
        {{ address }}
      </Paragraph>
    </div>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
  max-width: var(--max-width-qr);
  width: 100%;
  padding: var(--spacing-32) var(--spacing-16) var(--spacing-16);
  text-align: center;
  border: 1px solid var(--border-neutral);
  border-radius: var(--rounding-16);
}

.qr {
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}

.address {
  word-break: break-all;
}
</style>
