<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useDepositStore } from "@/entities/deposit";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { bridgeService } from "@/shared/services";
import { Button, Typography } from "@/shared/ui";
import { PaymentDetails, isBank } from "@/widgets/india";

const { t } = useI18n();

const { qrDetail } = storeToRefs(useDepositStore());

const { paymentMethod } = storeToRefs(usePaymentMethodStore());

const translations = computed(() => {
  if (isBank(paymentMethod.value!.name)) {
    return {
      title: t("deposit.india.stepOne.bankTitle"),
      text: t("deposit.india.stepOne.bankText"),
    };
  }

  return {
    title: t("deposit.india.stepOne.title"),
    text: null,
  };
});
</script>

<template>
  <div class="rounded-2xl bg-gray-50 px-4 py-6 dark:bg-oxford">
    <Typography
      :class="translations.text ? 'mb-2' : 'mb-4'"
      size="lg"
      variant="h3"
      weight="medium"
    >
      {{ translations.title }}
    </Typography>
    <Typography
      v-if="translations.text"
      class="mb-6 text-gray-350 dark:text-pearl"
      size="lg"
    >
      {{ translations.text }}
    </Typography>
    <PaymentDetails />
    <Button
      v-if="qrDetail"
      class="mt-8"
      color="secondary"
      full-width
      @click="bridgeService.notifyNative({ messageType: 'extNavigation', uri: qrDetail.value })"
    >
      {{ t("button.pay") }}
    </Button>
  </div>
</template>
