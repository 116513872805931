type Listener = "onGoBack" | "onSwipeBack";

type Payload =
  | CloseEvent
  | CopyEvent
  | CreateTransactionEvent
  | ExternalBrowserEvent
  | ExternalNavigationEvent
  | LoadedEvent
  | OpenRuleEvent
  | ShareEvent;

interface CloseEvent {
  messageType: "onClose";
}

interface CopyEvent {
  copiedMessage: string;
  messageType: "onCopyToBuffer";
}

interface CreateTransactionEvent {
  eventType: "deposit" | "withdrawal";
  messageType: "onCreateTransaction";
  payload: {
    eventId: number;
  };
}

interface ExternalBrowserEvent {
  messageType: "openExtBrowser";
  url: string;
}

interface ExternalNavigationEvent {
  messageType: "extNavigation";
  uri: string;
}

interface LoadedEvent {
  messageType: "Loaded";
}

interface OpenRuleEvent {
  messageType: "openRule";
  ruleId: number;
}

interface ShareEvent {
  messageType: "onShare";
  sharedMessage: string;
}

class BridgeService {
  addNativeListener(type: Listener, listener: EventListenerOrEventListenerObject) {
    window.addEventListener(type, listener);
  }

  notifyNative(payload: Payload) {
    const data = JSON.stringify(payload);

    if (window.webkit) {
      window.webkit.messageHandlers.message.postMessage(data);
    } else {
      window.billingBridge?.nativePostMessage?.(data);
    }
  }

  removeNativeListener(type: Listener, listener: EventListenerOrEventListenerObject) {
    window.removeEventListener(type, listener);
  }
}

export const bridgeService = new BridgeService();
