<script setup lang="ts">
import type { CssClass } from "@/shared/ui-v2";

interface Props {
  color?: "informative" | "inherit" | "negative" | "primary" | "secondary" | "tertiary" | "warning";
  size?: "m" | "m-sb" | "l-sb";
}

interface Slots {
  default(props: object): unknown;
}

withDefaults(defineProps<Props>(), {
  color: "inherit",
  size: "m",
});

defineSlots<Slots>();

const colors: Record<NotUndefined<Props["color"]>, CssClass> = {
  informative: "color-informative",
  inherit: "",
  negative: "color-negative",
  primary: "color-primary",
  secondary: "color-secondary",
  tertiary: "color-tertiary",
  warning: "color-warning",
};

const sizes: Record<NotUndefined<Props["size"]>, CssClass> = {
  m: "size-m",
  "m-sb": "size-m-sb",
  "l-sb": "size-l-sb",
};
</script>

<template>
  <p :class="[$style[colors[color]], $style[sizes[size]]]">
    <slot />
  </p>
</template>

<style module lang="postcss">
.color-informative {
  color: var(--informative);
}

.color-negative {
  color: var(--negative);
}

.color-primary {
  color: var(--fg-primary);
}

.color-secondary {
  color: var(--fg-secondary);
}

.color-tertiary {
  color: var(--fg-tertiary);
}

.color-warning {
  color: var(--warning);
}

.size-m {
  @add-mixin body-regular;
}

.size-m-sb {
  @add-mixin body-semibold;
}

.size-l-sb {
  @add-mixin large-body-semibold;
}
</style>
