<script setup lang="ts">
import "swiper/css";

import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";

import type { ImageGuideContent } from "@/entities/payment-method";
import { Guide } from "@/entities/payment-method";
import { Caption, Card, DotsPagination } from "@/shared/ui-v2";

type Emits = {
  change: [index: number];
};

interface Props {
  activeSlideIndex: number;
  content: ImageGuideContent[];
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const swiper = ref();

const onChange = (index: number) => emit("change", index);

const changeSwiper = (instance: unknown) => {
  swiper.value = instance;
};

defineExpose({
  swiper,
});
</script>

<template>
  <div :class="$style.root">
    <Card size="l">
      <Swiper
        auto-height
        :slides-per-view="1"
        @slide-change="(swiper) => onChange(swiper.activeIndex)"
        @swiper="changeSwiper"
      >
        <SwiperSlide
          v-for="(slide, index) of content"
          :key="index"
        >
          <Guide :content="slide">
            <template #beforeTitle>
              <Caption :class="$style.step">
                {{ index + 1 }}
              </Caption>
            </template>
            <template #default>
              <img
                :alt="slide.text"
                :class="$style.image"
                :src="slide.image"
              />
            </template>
          </Guide>
        </SwiperSlide>
      </Swiper>
    </Card>
    <DotsPagination
      v-if="content.length > 1"
      :active-index="activeSlideIndex"
      :count="content.length"
    />
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}

.step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size-control-xxs);
  height: var(--size-control-xxs);
  border-radius: var(--rounding-max);
  background-color: var(--informative);
  color: rgb(var(--color-white));
}

.image {
  display: block;
  max-height: var(--max-height-guide-image);
  margin: var(--spacing-0) auto var(--spacing-16) auto;
  border-radius: var(--rounding-16);
}
</style>
