<script setup lang="ts">
import QRCodeStyling from "qr-code-styling";
import { onMounted, ref } from "vue";

interface Props {
  options: Dictionary<unknown>;
  text: string;
}

const props = defineProps<Props>();

const qrRef = ref<Nullable<HTMLDivElement>>(null);

onMounted(() => {
  const instance = new QRCodeStyling({
    backgroundOptions: {
      color: "",
    },
    cornersDotOptions: {
      type: "dot",
    },
    cornersSquareOptions: {
      type: "extra-rounded",
    },
    data: props.text,
    dotsOptions: {
      type: "dots",
    },
    type: "svg",
    ...props.options,
  });
  instance.append(qrRef?.value ?? undefined);
});
</script>

<template>
  <div ref="qrRef" />
</template>
