import type { PaymentIcon, PaymentIconType, PaymentMethod } from "@/entities/payment-method";
import { paymentIcons, paymentIconTypes } from "@/entities/payment-method";
import type { Theme } from "@/entities/theme";
import type { Transaction } from "@/entities/transaction";
import type { User } from "@/entities/user";

const buildIconsDictionary = (payload: {
  country: User["country"];
  theme: Theme;
  transaction: Transaction;
  type: PaymentIconType;
}) => {
  const icons = paymentIcons.filter((icon) =>
    icon.supportedEntities.some(
      (entity) =>
        entity.country === payload.country &&
        entity.transactions.some((transaction) => transaction === payload.transaction),
    ),
  );

  return icons.reduce(
    (acc, icon) => ({
      ...acc,
      [icon.name]: icon.path?.[payload.type]?.[payload.theme] ?? icon.path.base[payload.theme],
    }),
    {} as Record<PaymentMethod["name"], FolderPath>,
  );
};

export const getPaymentIcon = (payload: {
  country: User["country"];
  name: PaymentIcon["name"];
  theme: Theme;
  transaction: Transaction;
  type: PaymentIconType;
}) => {
  const icons = buildIconsDictionary({
    country: payload.country,
    theme: payload.theme,
    transaction: payload.transaction,
    type: payload.type,
  });

  return icons[payload.name];
};

export const getAvailableCountries = () =>
  paymentIcons.flatMap((item) => item.supportedEntities.map((entity) => entity.country));

export const filterPaymentMethodsByIcon = (
  dictionary: Dictionary<PaymentMethod>,
  payload: {
    country: User["country"];
    theme: Theme;
    transaction: Transaction;
  },
) =>
  Object.values(dictionary).filter(({ name }) =>
    paymentIconTypes.some((type) =>
      getPaymentIcon({
        ...payload,
        name,
        type,
      }),
    ),
  );

export const mapPaymentMethod = (paymentMethod: PaymentMethod): PaymentMethod => {
  const getFieldName = (paymentMethod: PaymentMethod) => {
    if (paymentMethod.wallet) {
      return "customWallet";
    }

    return "text";
  };

  const { category, placeholder_text, placeholder_key, regex, regex_error_key, type, values } = paymentMethod;

  if (paymentMethod.regex) {
    return {
      ...paymentMethod,
      fields: [
        {
          category,
          name: getFieldName(paymentMethod),
          placeholder_key: placeholder_key || "",
          placeholder_text,
          regex: regex || "",
          regex_error_key: regex_error_key || `paymentMethods.${paymentMethod.name}`,
          type: type || null,
          values: values || [],
        },
      ],
    };
  }

  return {
    ...paymentMethod,
    fields: paymentMethod.fields ?? [],
  };
};
