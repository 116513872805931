<script setup lang="ts">
import { ListItem } from "@/shared/ui-v2";

type Emits = {
  change: [value: string | number];
};

interface Props {
  items: {
    text: string;
    value: string | number;
  }[];
  value: string | number;
}

interface Slots {
  item(props: { item: Props["items"][number] }): unknown;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

defineSlots<Slots>();

const onChange = (value: string | number) => emit("change", value);
</script>

<template>
  <div :class="$style.root">
    <ListItem
      v-for="item of items"
      :key="item.value"
      :item="item"
      :value="value"
      @change="onChange(item.value)"
    >
      <slot
        :item="item"
        name="item"
      />
    </ListItem>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: var(--max-height-popover);
  box-shadow: var(--box-shadow-popover);
  background-color: var(--bg-elevated);
}
</style>
