<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";

import type { PaymentGroup, PaymentMethod } from "@/entities/payment-method";
import { basePathsV2, cardViews, mapPaymentMethod, usePaymentMethodStore } from "@/entities/payment-method";
import { SectionWrapperV2 } from "@/entities/section";
import { useWithdrawalStore } from "@/entities/withdrawal";
import { useFormStore } from "@/features/form";
import { routeNamesV2 } from "@/shared/constants";
import { PaymentSystemsV2 } from "@/widgets/transaction";

const router = useRouter();

const paymentMethodStore = usePaymentMethodStore();
const { initialPaymentMethods, paymentGroup, paymentMethod, limits, filteredPaymentGroups, filteredPaymentMethods } =
  storeToRefs(paymentMethodStore);
const { changePaymentGroup, changePaymentMethod, changePaymentMethods } = paymentMethodStore;

const { changeConfirmationData, changeIsRuleShown } = useWithdrawalStore();

const { changeAmount, changeAmountValidity, changeFields, setFields } = useFormStore();

const resetData = () => {
  changeAmount("");
  changeAmountValidity(true);
  changeIsRuleShown(false);
  changeConfirmationData({
    confirmationRequested: false,
    email: "",
  });
  changeIsRuleShown(false);
  changePaymentGroup(null);
  changePaymentMethod(null);
  changePaymentMethods(initialPaymentMethods.value);
  setFields([]);
};

const selectPaymentGroup = async (paymentGroup: PaymentGroup) => {
  changePaymentGroup(paymentGroup);
  changePaymentMethods(Object.values(paymentGroup.paymentMethods));
  await router.push({ name: routeNamesV2.withdrawalGroupListPage });
};

const selectPaymentMethod = async (selectedMethod: PaymentMethod) => {
  const paymentMethod = mapPaymentMethod(selectedMethod);
  changePaymentMethod(paymentMethod);
  changeFields(paymentMethod.fields);
  changeAmount(String(limits.value.min));
  await router.push({
    name: basePathsV2[paymentMethod.cardViewType ?? cardViews.default] ?? routeNamesV2.withdrawalRedirectPage,
  });
};

onBeforeMount(resetData);
</script>

<template>
  <SectionWrapperV2 :class="$style.root">
    <PaymentSystemsV2
      :payment-group="paymentGroup"
      :payment-groups="filteredPaymentGroups"
      :payment-method="paymentMethod"
      :payment-methods="filteredPaymentMethods"
      transaction="withdrawal"
      @select-payment-group="selectPaymentGroup"
      @select-payment-method="selectPaymentMethod"
    />
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.root {
  @add-mixin scrollbar-hidden;

  overflow-y: auto;
}
</style>
