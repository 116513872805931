<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter } from "vue-router";

import type { PaymentMethod } from "@/entities/payment-method";
import { PaymentMethodTileV2, mapPaymentMethod, usePaymentMethodStore } from "@/entities/payment-method";
import { SectionGrid, SectionWrapperV2 } from "@/entities/section";
import type { Snippet } from "@/entities/user";
import { useUserStore } from "@/entities/user";
import { getAmount, useFormStore } from "@/features/form";
import { SearchEmpty, useSearch } from "@/features/search";
import { routeNamesV2 } from "@/shared/constants";
import { ScrollShadow } from "@/shared/ui";
import { InputSearch } from "@/shared/ui-v2";

const router = useRouter();

const paymentMethodStore = usePaymentMethodStore();
const { limits, sortedPaymentMethods } = storeToRefs(paymentMethodStore);
const { changePaymentMethod } = paymentMethodStore;

const { country, snippet } = storeToRefs(useUserStore());

const { changeAmount, changeFields } = useFormStore();

const { search, changeSearch } = useSearch();

const searchedMethods = computed(() =>
  sortedPaymentMethods.value.filter(({ text }) =>
    (text[country.value] ?? text.en ?? "").toLowerCase().includes(search.value.toLowerCase()),
  ),
);

const selectPaymentMethod = async (selectedMethod: PaymentMethod) => {
  const paymentMethod = mapPaymentMethod(selectedMethod);
  changePaymentMethod(paymentMethod);
  changeFields(paymentMethod.fields);
  const amount = getAmount(snippet.value as Snippet, limits.value);
  changeAmount(amount);
  await router.push({ name: routeNamesV2.depositCryptoAddressPage });
};
</script>

<template>
  <SectionWrapperV2 :class="$style.root">
    <InputSearch
      :class="$style.space"
      :value="search"
      @input="changeSearch"
    />
    <ScrollShadow v-if="searchedMethods.length > 0">
      <SectionGrid>
        <PaymentMethodTileV2
          v-for="method of searchedMethods"
          :key="method.name"
          :class="$style.tile"
          :payment-method="method"
          transaction="deposit"
          @change="selectPaymentMethod"
        />
      </SectionGrid>
    </ScrollShadow>
    <SearchEmpty v-else />
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.space {
  margin: var(--spacing-0) var(--spacing-4);
}

.tile {
  margin: var(--spacing-4);
}
</style>
