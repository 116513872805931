<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { bridgeService } from "@/shared/services";
import { Button, IconBox } from "@/shared/ui-v2";

const { t } = useI18n();
</script>

<template>
  <Button
    variant="plain"
    @click="bridgeService.notifyNative({ messageType: 'onClose' })"
  >
    <IconBox
      name="support"
      size="s"
    />
    {{ t("button.support") }}
  </Button>
</template>
