import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { computed } from "vue";

import type { PaymentMethod } from "@/entities/payment-method";
import { paymentMethodsWithAnnotation } from "@/entities/payment-method";
import type { Transaction } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";

interface Props {
  paymentMethod: Ref<Nullable<PaymentMethod>>;
  transaction: Transaction;
}

export const useAnnotation = (props: Props) => {
  const { country } = storeToRefs(useUserStore());

  const annotation = computed(() => {
    const method = paymentMethodsWithAnnotation.find(
      (method) =>
        method.country === country.value &&
        method.name === props.paymentMethod?.value?.name &&
        method.transaction === props.transaction,
    );
    return method?.text ?? "";
  });

  return {
    annotation,
  };
};
