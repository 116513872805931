import type { TransactionStatusKey, TransactionStatusValues } from "@/entities/transaction";

export const transactionStatuses: Readonly<Record<TransactionStatusKey, TransactionStatusKey>> = {
  accepted: "accepted",
  mistaked: "mistaked",
  processed: "processed",
  waiting: "waiting",
};

export const transactionStatusValues: Readonly<TransactionStatusValues> = {
  1: "processed",
  2: "mistaked",
};
