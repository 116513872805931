<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, h, onMounted } from "vue";

import type { Deposit } from "@/entities/deposit";
import { SectionWrapper } from "@/entities/section";
import { useThemeStore } from "@/entities/theme";
import type { Transaction, TransactionStatusKey } from "@/entities/transaction";
import { transactionStatuses, openBrowser } from "@/entities/transaction";
import type { Withdrawal } from "@/entities/withdrawal";
import CompleteCoinIMG from "@/shared/assets/images/complete-coin.png";
import { bridgeService } from "@/shared/services";
import { Spinner } from "@/shared/ui";
import type { Translations } from "@/widgets/transaction";
import { TransactionButtons, TransactionLoader, TransactionTicket } from "@/widgets/transaction";

type Emits = {
  backToPaymentPage: [];
  changeTransactionStatus: [value: TransactionStatusKey];
};

interface Props {
  apiResponse?: Deposit["apiResponse"] | Withdrawal["apiResponse"];
  transaction: Transaction;
  transactionStatus: TransactionStatusKey;
  translations: Translations;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  apiResponse: undefined,
});

const { isDarkTheme } = storeToRefs(useThemeStore());

const REQUEST_STOP_TIME = 1_000 * 60;

const content = computed(() => ({
  accepted: {
    additionalButton: {
      callback: () => onBackToPaymentPage(),
      text: translationsByStatus.value.additionalButton,
    },
    component: h("img", {
      src: CompleteCoinIMG,
    }),
    mainButton: {
      callback: () => bridgeService.notifyNative({ messageType: "onClose" }),
      text: translationsByStatus.value.mainButton,
    },
    text: translationsByStatus.value.text,
    title: translationsByStatus.value.title,
  },
  mistaked: {
    additionalButton: null,
    component: h("img", {
      src: CompleteCoinIMG,
    }),
    mainButton: {
      callback: () => onBackToPaymentPage(),
      text: translationsByStatus.value.mainButton,
    },
    text: translationsByStatus.value.text,
    title: translationsByStatus.value.title,
  },
  processed: {
    additionalButton: {
      callback: () => onBackToPaymentPage(),
      text: translationsByStatus.value.additionalButton,
    },
    component: h("img", {
      src: CompleteCoinIMG,
    }),
    mainButton: {
      callback: () => bridgeService.notifyNative({ messageType: "onClose" }),
      text: translationsByStatus.value.mainButton,
    },
    text: translationsByStatus.value.text,
    title: translationsByStatus.value.title,
  },
  waiting: {
    additionalButton: null,
    component: h(Spinner, {
      class: "text-blue-cyan-500 dark:text-white",
      backgroundColor: isDarkTheme.value ? "#FFFFFF80" : "#EBF4FF",
      color: "auto",
    }),
    mainButton: {
      callback: () => {
        if (props.apiResponse?.source) {
          openBrowser(props.apiResponse);
        }
      },
      text: translationsByStatus.value.mainButton,
    },
    text: translationsByStatus.value.text,
    title: translationsByStatus.value.title,
  },
}));

const contentByStatus = computed(() => content.value[props.transactionStatus]);
const translationsByStatus = computed(() => props.translations.status[props.transactionStatus]);

const onBackToPaymentPage = () => emit("backToPaymentPage");
const onChangeTransactionStatus = (value: TransactionStatusKey) => emit("changeTransactionStatus", value);

onMounted(() => {
  setTimeout(() => {
    if (props.transactionStatus === transactionStatuses.waiting) {
      onChangeTransactionStatus(transactionStatuses.accepted);
    }
  }, REQUEST_STOP_TIME);
});
</script>

<template>
  <SectionWrapper>
    <TransactionLoader
      v-if="transactionStatus === transactionStatuses.waiting"
      :content="contentByStatus"
    />
    <TransactionTicket
      v-else
      :content="contentByStatus"
      :transaction="transaction"
      :transaction-status="transactionStatus"
      :translations="translations"
    />
    <TransactionButtons
      class="mt-auto"
      :content="contentByStatus"
    />
  </SectionWrapper>
</template>
