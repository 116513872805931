<script setup lang="ts">
import { onBeforeMount, ref } from "vue";

import { fetchIcon } from "@/shared/lib";
import type { CssClass } from "@/shared/ui-v2";

interface Props {
  color?: "inherit" | "accent" | "informative" | "secondary" | "tertiary" | "warning";
  name: Nullable<string>;
  size?: "auto" | "full" | "xs" | "s" | "m" | "l" | "xl";
}

const props = withDefaults(defineProps<Props>(), {
  color: "inherit",
  size: "auto",
});

const colors: Record<NotUndefined<Props["color"]>, CssClass> = {
  inherit: "",
  accent: "color-accent",
  informative: "color-informative",
  secondary: "color-secondary",
  tertiary: "color-tertiary",
  warning: "color-warning",
};

const sizes: Record<NotUndefined<Props["size"]>, CssClass> = {
  auto: "",
  full: "size-full",
  xs: "size-xs",
  s: "size-s",
  m: "size-m",
  l: "size-l",
  xl: "size-xl",
};

const html = ref("");

const changeHtml = (data: string) => {
  html.value = data;
};

onBeforeMount(async () => {
  const icon = await fetchIcon(props.name ?? "none");
  changeHtml(icon);
});
</script>

<template>
  <div
    v-sanitize-html="html"
    :class="[$style.root, $style[colors[color]], $style[sizes[size]]]"
  />
</template>

<style module lang="postcss">
.root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: color 0.25s;
}

.size-full {
  & > svg {
    height: 100%;
  }
}

.size-xs {
  & > svg {
    width: 12px;
    height: 12px;
  }
}

.size-s {
  & > svg {
    width: 16px;
    height: 16px;
  }
}

.size-m {
  & > svg {
    width: 24px;
    height: 24px;
  }
}

.size-l {
  & > svg {
    width: 32px;
    height: 32px;
  }
}

.size-xl {
  & > svg {
    width: 64px;
    height: 64px;
  }
}

.color-accent {
  color: var(--brand-accent);
}

.color-informative {
  color: var(--informative);
}

.color-secondary {
  color: var(--fg-secondary);
}

.color-tertiary {
  color: var(--fg-tertiary);

  &:hover {
    color: var(--fg-tertiary-hover);
  }
}

.color-warning {
  color: var(--warning);
}
</style>
