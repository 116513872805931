<script setup lang="ts">
import type { CssClass } from "@/shared/ui-v2";
import { IconBox } from "@/shared/ui-v2";

type Emits = {
  toggle: [];
};

interface Props {
  color?: "accent" | "primary";
  isChecked?: boolean;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  color: "primary",
  isChecked: false,
});

const colors: Record<NotUndefined<Props["color"]>, CssClass> = {
  accent: "color-accent",
  primary: "color-primary",
};

const onToggle = () => emit("toggle");
</script>

<template>
  <label :class="$style.root">
    <input
      :checked="isChecked"
      :class="$style.input"
      type="checkbox"
      @change="onToggle"
    />
    <span :class="[$style.checkbox, isChecked && $style[colors[color]]]">
      <IconBox
        v-if="isChecked"
        name="check-mark"
      />
    </span>
  </label>
</template>

<style module lang="postcss">
.root {
  display: inline-flex;
  width: fit-content;
}

.input {
  @add-mixin sr-only;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size-checkbox);
  height: var(--size-checkbox);
  padding: var(--spacing-4);
  border: 1px solid var(--border-neutral);
  border-radius: var(--rounding-max);
  cursor: pointer;
}

.color-accent {
  background-color: var(--brand-accent);
  color: var(--fg-brand-accent);

  &:hover {
    background-color: var(--brand-accent-hover);
  }
}

.color-primary {
  background-color: var(--brand-primary);
  color: var(--fg-brand-primary);

  &:hover {
    background-color: var(--brand-primary-hover);
  }
}
</style>
