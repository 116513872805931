import { storeToRefs } from "pinia";
import qs from "qs";

import type { Deposit } from "@/entities/deposit";
import { useUserStore } from "@/entities/user";
import type { Withdrawal } from "@/entities/withdrawal";
import { bridgeService } from "@/shared/services";

const buildQuery = (apiResponse: Deposit["apiResponse"] | Withdrawal["apiResponse"]) => {
  const url = new URL(apiResponse.source);
  const query = Object.keys(apiResponse.data).length > 0 ? `?${qs.stringify(apiResponse.data)}` : "";
  return `${url.origin}${url.pathname}${query}${url.hash}`;
};

export const openBrowser = (apiResponse: Deposit["apiResponse"] | Withdrawal["apiResponse"]) => {
  const { isNativePlatform } = storeToRefs(useUserStore());

  const url = buildQuery(apiResponse);

  if (isNativePlatform.value) {
    bridgeService.notifyNative({ messageType: "openExtBrowser", url });
    return;
  }

  window.open(url);
};
