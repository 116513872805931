<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import type { CryptoApiResponse, Deposit } from "@/entities/deposit";
import { useDepositStore } from "@/entities/deposit";
import type { PaymentMethodNetwork } from "@/entities/payment-method";
import { PaymentHeader, usePaymentMethodStore } from "@/entities/payment-method";
import { SectionButtonV2, SectionSpinnerV2, SectionWrapperV2 } from "@/entities/section";
import { transactionStatuses } from "@/entities/transaction";
import { SelectNetwork } from "@/features/crypto";
import { CRYPTO_NETWORK_FIELD } from "@/features/form";
import { routeNamesV2 } from "@/shared/constants";
import { ScrollShadow } from "@/shared/ui";
import { CryptoInfoV2 } from "@/widgets/crypto";

const { t } = useI18n();
const router = useRouter();

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const depositStore = useDepositStore();
const { apiResponse, isLoadingDeposit } = storeToRefs(depositStore);
const { changeTransactionStatus } = depositStore;

const networks = computed(() => {
  const values = (paymentMethod.value?.fields?.find((field) => field.name === CRYPTO_NETWORK_FIELD)?.values ??
    []) as PaymentMethodNetwork[];

  if (values.length) {
    return values.map((value) => ({ text: value, value }));
  }

  if (paymentMethod.value?.network) {
    return [{ text: paymentMethod.value.network, value: paymentMethod.value.network }];
  }

  return [];
});

const response = computed(
  () => apiResponse.value as Omit<Deposit["apiResponse"], "data"> & { data: CryptoApiResponse },
);

const handleClick = async () => {
  changeTransactionStatus(transactionStatuses.accepted);
  await router.push({ name: routeNamesV2.depositCompletePage });
};
</script>

<template>
  <SectionWrapperV2>
    <ScrollShadow :class="$style.container">
      <PaymentHeader
        :class="$style.space"
        :payment-method="paymentMethod"
        transaction="deposit"
      >
        {{ paymentLabel }}
      </PaymentHeader>
      <SelectNetwork
        :class="$style.space"
        :items="networks"
      />
      <SectionSpinnerV2 v-if="isLoadingDeposit" />
      <CryptoInfoV2
        v-else-if="response"
        :class="$style.space"
        :response="response"
      />
    </ScrollShadow>
    <SectionButtonV2
      v-if="!isLoadingDeposit"
      :class="[$style.button, $style.space]"
      color="accent"
      @click="handleClick"
    >
      {{ t("button.paid") }}
    </SectionButtonV2>
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.space {
  margin: var(--spacing-0) var(--spacing-4);
}

.button {
  margin-top: auto;
}
</style>
