<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { Caption, Spinner, Timer } from "@/shared/ui-v2";

type Emits = {
  expire: [];
};

interface Props {
  duration: number;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const { t } = useI18n();

const onExpire = () => emit("expire");
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.container">
      <Spinner size="s" />
      <Caption color="secondary">
        {{ t("timer.text") }}
      </Caption>
    </div>
    <Timer
      :class="$style.timer"
      color="primary"
      :duration="duration"
      @expire="onExpire"
    />
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-12);
}

.container {
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
  overflow: hidden;
}

.timer {
  background-color: var(--bg-secondary);
}
</style>
