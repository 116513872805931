import { isDesktop } from "@/entities/theme";
import type { Snippet, User } from "@/entities/user";

export const defaultUser: Readonly<User> = {
  activity: 0,
  address: "",
  balanceData: {
    primary: {
      amount: 0,
      currency: "MXN",
    },
  },
  birthday: 0,
  bk_type: "",
  country: "mx",
  currency: "MXN",
  deposits_calc_count: 0,
  deposits_calc_time: "",
  email: "",
  firstdeposit_time: null,
  hash_id: null,
  id: 0,
  id_user: 0,
  lang: "en",
  last_ip: "",
  last_ip_country: "ru",
  meta: {},
  name: "",
  note: null,
  paymentGeo: "MX",
  platform: isDesktop ? "desktop" : "mobile",
  partner_key: "",
  passport: "",
  passport_by: "",
  phone: "",
  poker_nickname: null,
  regulated_domain_id: 0,
  role: 0,
  tg_id: null,
  time_registration: "",
  timezone: null,
  token: "",
};

export const snippetMock: Readonly<Snippet> = {
  mostPaymentMethod: "airtm",
  paymentAmountSnippet: 1_000,
  payment_ab_flag: null,
};
