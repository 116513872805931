import type { App } from "vue";
import { createI18n } from "vue-i18n";

import type { User } from "@/entities/user";
import en from "@/shared/locales/en.json";
import hindi from "@/shared/locales/in.json";
import mr from "@/shared/locales/mr.json";
import mx from "@/shared/locales/mx.json";
import ru from "@/shared/locales/ru.json";
import te from "@/shared/locales/te.json";

interface Options {
  user: User;
}

const DEFAULT_LOCALE = "en";

export const i18n = createI18n({
  legacy: false,
  locale: DEFAULT_LOCALE,
  messages: {
    en,
    hi: hindi,
    in: hindi,
    mr,
    mx,
    ru,
    te,
  },
});

export default {
  install: (_: App, options: Options) => {
    i18n.global.locale.value = options.user.lang;
  },
};
