import "@/app/index.css";
import { createApp } from "vue";

import App from "@/app";
import { clickOutsideDirective, sanitizeHtmlDirective } from "@/app/directives";
import {
  configPlugin,
  consolePlugin,
  datadogPlugin,
  i18n,
  i18nPlugin,
  socketPlugin,
  stylesPlugin,
} from "@/app/plugins";
import { router, routerV2, store } from "@/app/providers";
import { initApp } from "@/shared/lib";

const app = createApp(App);

app.directive("click-outside", clickOutsideDirective);
app.directive("sanitize-html", sanitizeHtmlDirective);

app.use(configPlugin);
app.use(store);

const { isLegacyDesign, user } = await initApp();

app.use(datadogPlugin);
app.use(i18n);
app.use(i18nPlugin, { user });
app.use(socketPlugin, { user });
app.use(stylesPlugin, { isLegacyDesign, user });
app.use(consolePlugin);
app.use(isLegacyDesign ? router : routerV2);

app.mount("#app");
