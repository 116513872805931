<script setup lang="ts">
import { storeToRefs } from "pinia";

import type { PaymentMethod } from "@/entities/payment-method";
import { PaymentMethodIcon } from "@/entities/payment-method";
import type { Transaction } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";
import { Caption, Card } from "@/shared/ui-v2";

type Emits = {
  change: [paymentMethod: PaymentMethod];
};

interface Props {
  paymentMethod: PaymentMethod;
  transaction: Transaction;
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const { country } = storeToRefs(useUserStore());

const onChange = () => emit("change", props.paymentMethod);
</script>

<template>
  <Card
    :class="$style.root"
    is-pressable
    @click="onChange"
  >
    <PaymentMethodIcon
      :name="paymentMethod.name"
      size="l"
      :transaction="transaction"
      type="base"
    />
    <Caption
      :class="$style.label"
      size="s-sb"
    >
      {{ paymentMethod?.text[country] ?? paymentMethod?.text.en }}
    </Caption>
  </Card>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-8);
  text-align: start;
}

.label {
  @add-mixin text-ellipsis;

  width: 100%;
}
</style>
