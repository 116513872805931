<script setup lang="ts">
import { storeToRefs } from "pinia";

import type { PaymentIcon, PaymentMethod } from "@/entities/payment-method";
import { getPaymentIcon } from "@/entities/payment-method";
import { useThemeStore } from "@/entities/theme";
import type { Transaction } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";
import type { CssClass } from "@/shared/ui-v2";
import { IconBox } from "@/shared/ui-v2";

interface Props {
  name: PaymentMethod["name"];
  size: "xs" | "s" | "m" | "l" | "xl";
  transaction: Transaction;
  type: NotUndefined<keyof PaymentIcon["path"]>;
}

defineProps<Props>();

const sizes: Record<NotUndefined<Props["size"]>, CssClass> = {
  xs: "size-xs",
  s: "size-s",
  m: "size-m",
  l: "size-l",
  xl: "size-xl",
};

const { theme } = storeToRefs(useThemeStore());

const { country } = storeToRefs(useUserStore());
</script>

<template>
  <IconBox
    :class="$style[sizes[size]]"
    :name="
      getPaymentIcon({
        country,
        name,
        theme,
        transaction,
        type,
      })
    "
    size="full"
  />
</template>

<style module lang="postcss">
.size-xs {
  height: 12px;
}

.size-s {
  height: 16px;
}

.size-m {
  height: 24px;
}

.size-l {
  height: 32px;
}

.size-xl {
  height: 64px;
}
</style>
