<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { PaymentHeader, nigerianBankIcons, usePaymentMethodStore } from "@/entities/payment-method";
import { SectionGrid, SectionWrapperV2 } from "@/entities/section";
import { useThemeStore } from "@/entities/theme";
import { useWithdrawalStore } from "@/entities/withdrawal";
import { SubmitButtonV2, useFormStore, NIGERIAN_BANK_FIELD } from "@/features/form";
import { SearchEmpty, useSearch } from "@/features/search";
import { routeNamesV2, transitions } from "@/shared/constants";
import { ScrollShadow } from "@/shared/ui";
import { Caption, Card, IconBox, InputSearch } from "@/shared/ui-v2";
import { WithdrawalFormV2 } from "@/widgets/withdrawal";

const { t } = useI18n();

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const { theme } = storeToRefs(useThemeStore());

const withdrawalStore = useWithdrawalStore();
const { isLoadingWithdrawal } = storeToRefs(withdrawalStore);
const { onSubmit } = withdrawalStore;

const { changeFieldProps } = useFormStore();

const { search, changeSearch } = useSearch();

const nigerianBank = ref();

const banks = computed(() => {
  const field = (paymentMethod.value?.fields ?? []).find((field) => field.name === NIGERIAN_BANK_FIELD);

  if (!field) {
    return [];
  }

  return (field.values ?? []).map((value) => ({
    icon: nigerianBankIcons?.[value]?.[theme.value] ?? "payment-methods/common/dark/bank-transfer",
    name: value,
  }));
});

const searchedBanks = computed(() =>
  banks.value.filter((bank) => bank.name.toLowerCase().includes(search.value.toLowerCase())),
);

const changeNigerianBank = (value: string) => {
  nigerianBank.value = value;
};

const handleClick = (value: string) => {
  changeFieldProps(NIGERIAN_BANK_FIELD, { value });
  changeNigerianBank(value);
};
</script>

<template>
  <SectionWrapperV2 :class="$style.root">
    <PaymentHeader
      :class="$style.space"
      :payment-method="paymentMethod"
      transaction="withdrawal"
    >
      {{ paymentLabel }}
    </PaymentHeader>
    <InputSearch
      :class="$style.space"
      :value="search"
      @input="changeSearch"
    />
    <ScrollShadow v-if="searchedBanks.length > 0">
      <SectionGrid>
        <Card
          v-for="bank of searchedBanks"
          :key="bank.name"
          :class="[$style.card, nigerianBank === bank.name && $style.selected]"
          is-pressable
          @click="handleClick(bank.name)"
        >
          <IconBox
            :class="$style.icon"
            :name="bank.icon"
            size="full"
          />
          <Caption
            :class="$style.label"
            size="s-sb"
          >
            {{ bank.name }}
          </Caption>
        </Card>
      </SectionGrid>
    </ScrollShadow>
    <SearchEmpty v-else />
    <Transition :name="transitions.slideDown">
      <div
        v-if="nigerianBank"
        :class="$style.bottom"
      >
        <WithdrawalFormV2 :class="$style.space" />
        <SubmitButtonV2
          :class="$style.space"
          :is-loading="isLoadingWithdrawal"
          @click="onSubmit({}, routeNamesV2.withdrawalCompletePage)"
        >
          {{ t("deposit.button") }}
        </SubmitButtonV2>
      </div>
    </Transition>
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.space {
  margin: var(--spacing-0) var(--spacing-4);
}

.card {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  margin: var(--spacing-4);
  text-align: start;

  &.selected {
    outline: var(--border-focus) solid var(--spacing-2);
    outline-offset: var(--spacing-2);
  }
}

.icon {
  height: 32px;
}

.label {
  @add-mixin text-ellipsis;

  width: 100%;
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
</style>
