<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useDepositStore } from "@/entities/deposit";
import type { HostToHostApiResponse, Deposit } from "@/entities/deposit";
import { paymentMethodsWithVirtualAccounts } from "@/entities/host-to-host";
import { PaymentHeader, usePaymentMethodStore } from "@/entities/payment-method";
import { SectionWrapperV2 } from "@/entities/section";
import { transactionStatuses } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";
import { routeNamesV2 } from "@/shared/constants";
import { ScrollShadow } from "@/shared/ui";
import { Alert, Button, Countdown } from "@/shared/ui-v2";
import { Invoice } from "@/widgets/host-to-host";

const { t, te } = useI18n();
const router = useRouter();

const depositStore = useDepositStore();
const { apiResponse } = storeToRefs(depositStore);
const { changeTransactionStatus } = depositStore;

const { isBankTransfer, paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const { user } = storeToRefs(useUserStore());

const isExpired = ref(false);

const countdownDuration = computed(() => {
  const { created, timeout_minutes } = data.value;

  const time = new Date(created).getTime();
  const timeout = timeout_minutes * 1_000 * 60;

  return time + timeout - Date.now();
});

const data = computed(() => {
  if (isBankTransfer.value) {
    return {
      card: {
        account_number: response.value.data.accountNumber,
      },
      created: new Date().toISOString(),
      payment_amount: response.value.data.payment_amount,
      payment_currency: response.value.data.payment_currency ?? user.value.currency,
      payment_system: paymentMethod.value?.name ?? "",
      timeout_minutes: 15,
    } as HostToHostApiResponse;
  }

  return response.value.data;
});

const description = computed(() => {
  const specificTranslationKey = `hostToHost.invoiceByMethods.${paymentMethod.value?.name ?? ""}.description`;

  if (te(specificTranslationKey)) {
    return t(specificTranslationKey);
  }

  return t("hostToHost.invoice.description");
});

const isVirtualAccount = computed(() => paymentMethodsWithVirtualAccounts.includes(paymentMethod.value?.name ?? ""));

const notification = computed(() => {
  const translationKey = `hostToHost.invoiceByMethods.${paymentMethod.value?.name ?? ""}.notification`;
  return {
    isExists: te(translationKey),
    text: t(translationKey),
  };
});

const response = computed(
  () =>
    apiResponse.value as Omit<Deposit["apiResponse"], "data"> & {
      data: HostToHostApiResponse;
    },
);

const changeExpirationStatus = (value: boolean) => {
  isExpired.value = value;
};

const handleClick = async () => {
  changeTransactionStatus(transactionStatuses.accepted);
  await router.push({ name: routeNamesV2.depositCompletePage });
};
</script>

<template>
  <SectionWrapperV2>
    <ScrollShadow :class="$style.container">
      <PaymentHeader
        :class="$style.space"
        :payment-method="paymentMethod"
        transaction="deposit"
      >
        {{ paymentLabel }}
      </PaymentHeader>
      <Invoice
        :class="$style.space"
        :currency="user.currency"
        :data="data"
        :is-virtual-account="isVirtualAccount"
      />
    </ScrollShadow>
    <Alert
      :class="$style.space"
      type="warning"
    >
      {{ description }}
    </Alert>
    <Alert
      v-if="notification.isExists"
      :class="$style.space"
      type="informative"
    >
      {{ notification.text }}
    </Alert>
    <Countdown
      :class="$style.space"
      :duration="countdownDuration"
      @expire="changeExpirationStatus(true)"
    />
    <Button
      v-if="!isExpired"
      :class="$style.button"
      color="accent"
      @click="handleClick"
    >
      {{ t("button.paid") }}
    </Button>
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.space {
  margin: var(--spacing-0) var(--spacing-4);
}

.button {
  margin: auto var(--spacing-0) var(--spacing-4) var(--spacing-4);
}
</style>
