<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useUserStore } from "@/entities/user";
import { useFormStore } from "@/features/form";
import { currencies } from "@/shared/constants";
import { formatNumber } from "@/shared/lib";
import { Typography } from "@/shared/ui";

const { t } = useI18n();

const { user } = storeToRefs(useUserStore());

const { amount } = storeToRefs(useFormStore());

const formattedAmount = computed(() =>
  formatNumber(
    {
      currency: user.value.currency,
      style: "decimal",
    },
    +amount.value,
  ),
);
</script>

<template>
  <div class="flex flex-col gap-1">
    <Typography
      class="text-xs text-gray-350 dark:text-pearl"
      size="auto"
    >
      {{ t("withdrawal.confirm.amountText") }}
    </Typography>
    <div class="flex gap-1 text-3xl">
      <Typography
        class="text-gray-350 dark:text-pearl"
        size="auto"
        weight="bold"
      >
        {{ currencies[user.currency].symbol }}
      </Typography>
      <Typography
        class="text-gray-900 dark:text-white"
        size="auto"
        weight="bold"
      >
        {{ formattedAmount }}
      </Typography>
    </div>
  </div>
</template>
