<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";

import type { PaymentMethod } from "@/entities/payment-method";
import { filterPaymentMethodsByIcon, getAvailableCountries, usePaymentMethodStore } from "@/entities/payment-method";
import { SectionSpinnerV2, SectionView } from "@/entities/section";
import { useThemeStore } from "@/entities/theme";
import { useUserStore } from "@/entities/user";
import { routeNamesV2 } from "@/shared/constants";
import { fetchData, handlePageTransition } from "@/shared/lib";
import { bridgeService } from "@/shared/services";
import { Container } from "@/shared/ui-v2";

const route = useRoute();
const router = useRouter();

const paymentMethodStore = usePaymentMethodStore();
const { isFetchingWithdrawalMethods, withdrawalMethodsResponse } = storeToRefs(paymentMethodStore);
const { changeInitialPaymentMethods, changePaymentMethods, fetchWithdrawalMethods } = paymentMethodStore;

const { country } = storeToRefs(useUserStore());

const { theme } = storeToRefs(useThemeStore());

const setData = (paymentMethods: Dictionary<PaymentMethod>) => {
  const methods = filterPaymentMethodsByIcon(paymentMethods, {
    country: country.value,
    theme: theme.value,
    transaction: "withdrawal",
  });
  changeInitialPaymentMethods(methods);
  changePaymentMethods(methods);
};

const handler = async () => {
  await handlePageTransition(route, router, () => bridgeService.notifyNative({ messageType: "onClose" }));
};

onBeforeMount(async () => {
  const countries = getAvailableCountries();

  if (!countries.includes(country.value)) {
    await router.push({
      name: routeNamesV2.emptyPage,
      query: {
        refreshUrl: window.location.href,
      },
    });
    return;
  }

  bridgeService.addNativeListener("onGoBack", handler);
  bridgeService.addNativeListener("onSwipeBack", handler);

  await fetchData([fetchWithdrawalMethods()], router, routeNamesV2.errorPage);
  setData(withdrawalMethodsResponse.value as Dictionary<PaymentMethod>);
});

onBeforeUnmount(() => {
  bridgeService.removeNativeListener("onGoBack", handler);
  bridgeService.removeNativeListener("onSwipeBack", handler);
});
</script>

<template>
  <section>
    <Container>
      <SectionSpinnerV2 v-if="isFetchingWithdrawalMethods" />
      <SectionView v-else />
    </Container>
  </section>
</template>
