<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import type { Limits } from "@/entities/payment-method";
import { useUserStore } from "@/entities/user";
import { NUMBER_WITH_COMMAS_REGEX } from "@/features/form";
import { currencies } from "@/shared/constants";
import { formatNumber, getCurrencyFractionDigits } from "@/shared/lib";
import { Slider, Typography } from "@/shared/ui";

type Emits = {
  focus: [];
  input: [value: string];
};

interface Props {
  hasErrorMessage: boolean;
  limits: Limits;
  value: string;
}

interface Slots {
  append(props: object): unknown;
  label(props: object): unknown;
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

defineSlots<Slots>();

const validators = {
  isLeadingDot: (value: string) => value === ".",
  isNonDecimalLeadingZero: (value: string) => {
    const [first, second] = value;
    return first === "0" && value.length > 1 && second !== ".";
  },
  isInvalidOrExceedsLimit: (value: string) => {
    const isValid = (value: string) => {
      const max = getCurrencyFractionDigits(user.value.currency);
      return new RegExp(`^(\\d+((,\\d+)?)+)?(\\.\\d{0,${max}})?$`).test(value);
    };

    const numericValue = value.replace(NUMBER_WITH_COMMAS_REGEX, "");
    const minValueLength = `${Math.floor(+numericValue)}`.length;
    const maxDigitsLength = `${Math.floor(props.limits.max)}`.length + 1;

    return !isValid(value) || minValueLength > maxDigitsLength;
  },
};

const { t } = useI18n();

const { user } = storeToRefs(useUserStore());

const inputRef = ref<Nullable<HTMLInputElement>>(null);

const placeholder = computed(() =>
  formatNumber(
    {
      currency: user.value.currency,
      style: "decimal",
    },
    props.limits.max,
  ),
);

const modifiedAmount = computed(() => {
  if (!props.value) {
    return "";
  }

  const [, fractionalPart = ""] = props.value.split(".");
  const formattedValue = formatNumber(
    {
      currency: user.value.currency,
      style: "decimal",
      minimumFractionDigits: fractionalPart.length,
    },
    +props.value,
  );

  return props.value.endsWith(".") ? formattedValue + "." : formattedValue;
});

const modifiedLimits = computed(() =>
  [props.limits.min, props.limits.max].map((limit) => formatNumber({ currency: user.value.currency }, limit)),
);

const errorText = computed(() => {
  if (+props.value < props.limits.min) {
    return t("fields.amount.min", {
      amount: formatNumber({ currency: user.value.currency }, props.limits.min),
    });
  }

  return t("fields.amount.max", {
    amount: formatNumber({ currency: user.value.currency }, props.limits.max),
  });
});

const rangeStep = computed(() => {
  if (Number.isInteger(props.limits.max - props.limits.min)) {
    return 1;
  }

  if (props.limits.max - +props.value < 1) {
    return convertToDecimal(getCurrencyFractionDigits(user.value.currency));
  }

  return 1;
});

const onFocus = () => emit("focus");
const onInput = (value: string) => emit("input", value);

const convertToDecimal = (value: number) => Math.pow(10, -value);

const handleInput = (event: Event) => {
  const value = (event as Event & { target: HTMLInputElement }).target.value;

  if (
    validators.isLeadingDot(value) ||
    validators.isNonDecimalLeadingZero(value) ||
    validators.isInvalidOrExceedsLimit(value)
  ) {
    const slicedValue = value.slice(0, value.length - 1);
    const replacedValue = slicedValue.replace(NUMBER_WITH_COMMAS_REGEX, "");
    // eslint-disable-next-line no-param-reassign
    (event as Event & { target: HTMLInputElement }).target.value = slicedValue;
    onInput(replacedValue);
    return;
  }

  onInput(value.replace(NUMBER_WITH_COMMAS_REGEX, ""));
};

const handleSlide = (event: Event) => {
  onFocus();
  handleInput(event);
};
</script>

<template>
  <div>
    <label
      class="flex cursor-text items-center justify-center gap-2 rounded-xl border px-4 py-2.5 transition"
      :class="[
        ...(hasErrorMessage
          ? ['border-orange-525', 'dark:border-ruby', 'bg-gray-50', 'dark:bg-ruby/[.1]']
          : ['border-transparent', 'bg-gray-50', 'dark:bg-oxford']),
      ]"
      @click.self="inputRef?.setSelectionRange(modifiedAmount.length, modifiedAmount.length)"
    >
      <span class="flex grow flex-col gap-1">
        <Typography
          class="text-xs text-gray-225 dark:text-pearl"
          size="auto"
        >
          <slot name="label" />
        </Typography>
        <span class="flex gap-1 text-2.5xl font-bold leading-8.5 tracking-wide text-gray-900 dark:text-white">
          <Typography
            class="select-none text-gray-350 transition dark:text-pearl"
            size="auto"
            variant="span"
            weight="bold"
          >
            {{ currencies[user.currency].symbol }}
          </Typography>
          <input
            ref="inputRef"
            class="w-full bg-transparent tracking-wide outline-none placeholder:text-gray-350 dark:placeholder:text-pearl [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-search-cancel-button]:appearance-none"
            inputmode="numeric"
            :placeholder="placeholder"
            type="text"
            :value="modifiedAmount"
            @focus="onFocus"
            @input="handleInput"
          />
        </span>
      </span>
      <slot name="append" />
    </label>
    <div class="flex flex-col gap-3.5 px-4">
      <Slider
        :max="limits.max"
        :min="limits.min"
        :step="rangeStep"
        :value="Number(value)"
        @input="handleSlide"
      />
      <div class="flex justify-between">
        <Typography
          v-for="limitSum of modifiedLimits"
          :key="limitSum"
          class="text-xs text-gray-350 dark:text-pearl"
          size="auto"
        >
          {{ limitSum }}
        </Typography>
      </div>
    </div>
    <Typography
      v-if="hasErrorMessage"
      class="px-4 pt-1.5"
      color="danger"
      size="sm"
    >
      {{ errorText }}
    </Typography>
  </div>
</template>
