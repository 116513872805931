<script setup lang="ts">
import type { CssClass } from "@/shared/ui-v2";
import { IconBox } from "@/shared/ui-v2";

interface Props {
  color?: "inherit" | "informative" | "secondary";
  name: string;
  size?: "auto" | "xs" | "s" | "m" | "l" | "xl";
}

withDefaults(defineProps<Props>(), {
  color: "inherit",
  size: "auto",
});

const colors: Record<NotUndefined<Props["color"]>, CssClass> = {
  inherit: "",
  informative: "color-informative",
  secondary: "color-secondary",
};

const sizes: Record<NotUndefined<Props["size"]>, CssClass> = {
  auto: "",
  xs: "size-xs",
  s: "size-s",
  m: "size-m",
  l: "size-l",
  xl: "size-xl",
};
</script>

<template>
  <div :class="[$style.root, $style[colors[color]], $style[sizes[size]]]">
    <IconBox
      :name="name"
      :size="size"
    />
  </div>
</template>

<style module lang="postcss">
.root {
  display: inline-flex;
  border-radius: var(--rounding-max);
}

.color-informative {
  background-color: var(--informative);
  color: rgb(var(--color-white));
}

.color-secondary {
  background-color: var(--fg-secondary);
  color: rgb(var(--color-white));
}

.size-xs {
  padding: var(--spacing-6);
}

.size-s {
  padding: var(--spacing-8);
}

.size-m {
  padding: var(--spacing-10);
}

.size-l {
  padding: var(--spacing-12);
}

.size-xl {
  padding: var(--spacing-16);
}
</style>
