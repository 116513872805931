<script setup lang="ts">
import { computed, h } from "vue";
import { useI18n } from "vue-i18n";

import { InvoiceData } from "@/entities/invoice";
import type { DecryptedCard, DepositCreateResponse } from "@/entities/peer-to-peer";
import { copyToClipboard, formatCardNumber, formatNumber } from "@/shared/lib";
import { IconBox, IconContainer } from "@/shared/ui-v2";

interface Props {
  decryptedCard?: DecryptedCard;
  hasConfirmationId: boolean;
  response: DepositCreateResponse;
}

const props = defineProps<Props>();

const { t } = useI18n();

const holderText = computed(() => {
  if (props.response.card?.bank_account) {
    return t("peerToPeer.invoice.fields.accountHolder");
  }

  return t("peerToPeer.invoice.fields.cardHolder");
});

const number = computed(() => {
  if (props.hasConfirmationId) {
    return {
      text: t("peerToPeer.invoice.fields.walletNumber"),
      value:
        props.response.card?.bank_account ??
        props.response.card?.phone ??
        formatCardNumber(props.response.card?.number ?? ""),
    };
  }

  if (props.response.card?.bank_account) {
    return {
      text: t("peerToPeer.invoice.fields.bankAccount"),
      value: props.response.card?.bank_account ?? "",
    };
  }

  if (props.response.card?.phone) {
    return {
      text: t("peerToPeer.invoice.fields.phoneNumber"),
      value: props.response.card?.phone ?? "",
    };
  }

  return {
    text: t("peerToPeer.invoice.fields.cardNumber"),
    value: props.decryptedCard?.cardNumber ?? formatCardNumber(props.response.card?.number ?? ""),
  };
});

const fields = computed(() => [
  {
    icon: {
      component: h(IconBox),
      props: {
        name: `currencies/${props.response.payment_currency.toLowerCase()}`,
        size: "m",
      },
    },
    isCopyable: true,
    text: t("peerToPeer.invoice.fields.amount"),
    value: formatNumber({ currency: props.response.payment_currency }, +props.response.payment_amount),
    onCopy: () => copyToClipboard(props.response.payment_amount),
  },
  {
    icon: {
      component: h(IconContainer),
      props: {
        color: "secondary",
        name: "withdraw",
        size: "xs",
      },
    },
    isCopyable: true,
    imageUrl: props.decryptedCard?.imageUrl,
    text: number.value.text,
    value: number.value.value,
    onCopy: () => copyToClipboard(number.value.value),
  },
  {
    icon: {
      component: h(IconContainer),
      props: {
        color: "secondary",
        name: "phone",
        size: "xs",
      },
    },
    isCopyable: true,
    text: t("peerToPeer.invoice.fields.phoneNumber"),
    value: props.response.card?.phone,
    onCopy: () => copyToClipboard(props.response.card?.phone ?? ""),
  },
  {
    icon: {
      component: h(IconContainer),
      props: {
        color: "secondary",
        name: "wallet",
        size: "xs",
      },
    },
    isCopyable: true,
    text: t("peerToPeer.invoice.fields.transactionDescription"),
    value: props.response.card?.transaction_description,
    onCopy: () => copyToClipboard(props.response.card?.transaction_description ?? ""),
  },
  {
    icon: {
      component: h(IconContainer),
      props: {
        color: "secondary",
        name: "user",
        size: "xs",
      },
    },
    isCopyable: false,
    text: holderText.value,
    value: props.response.card?.fullName ?? "",
  },
]);
</script>

<template>
  <template
    v-for="(field, index) of fields"
    :key="field.value"
  >
    <InvoiceData
      v-if="Boolean(field.value)"
      :icon="field.icon"
      :image-url="field.imageUrl"
      :is-copyable="field.isCopyable"
      :text="field.text"
      :value="field.value"
      :with-divider="index !== 0"
      @copy="field.onCopy"
    />
  </template>
</template>
