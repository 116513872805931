import type { PhoneCountry } from "@/features/form";

export const NUMBER_WITH_COMMAS_REGEX = new RegExp(/[^0-9.]+/g);

export const CRYPTO_NETWORK_FIELD = "network";
export const NIGERIAN_BANK_FIELD = "bank_name";

export const countries: Readonly<PhoneCountry[]> = [
  {
    name: "Russia (Россия)",
    code: "ru",
    prefix: "+7",
    example: "(912) 345 67-89",
  },
  {
    name: "Ukraine (Україна)",
    code: "ua",
    prefix: "+380",
    example: "50 123 4567",
  },
  {
    name: "American Samoa",
    code: "as",
    prefix: "+1",
    example: "684 733 1234",
  },
  {
    name: "Andorra",
    code: "ad",
    prefix: "+376",
    example: "312 345",
  },
  {
    name: "Angola",
    code: "ao",
    prefix: "+244",
    example: "923 123 456",
  },
  {
    name: "Anguilla",
    code: "ai",
    prefix: "+1264",
    example: "235 1234",
  },
  {
    name: "Antigua and Barbuda",
    code: "ag",
    prefix: "+1268",
    example: "464 1234",
  },
  {
    name: "Argentina",
    code: "ar",
    prefix: "+54",
    example: "9 11 2345 6789",
  },
  {
    name: "Armenia (Հայաստան)",
    code: "am",
    prefix: "+374",
    example: "77 123456",
  },
  {
    name: "Aruba",
    code: "aw",
    prefix: "+297",
    example: "560 1234",
  },
  {
    name: "Australia",
    code: "au",
    prefix: "+61",
    example: "412 345 678",
  },
  {
    name: "Austria (Österreich)",
    code: "at",
    prefix: "+43",
    example: "664 123 4?5?6? 7?8?",
  },
  {
    name: "Azerbaijan (Azərbaycan)",
    code: "az",
    prefix: "+994",
    example: "40 123 45 67",
  },
  {
    name: "Bahamas",
    code: "bs",
    prefix: "+1242",
    example: "359 1234",
  },
  {
    name: "Bahrain (\u202bالبحرين\u202c\u200e)",
    code: "bh",
    prefix: "+973",
    example: "3600 1234",
  },
  {
    name: "Bangladesh (বাংলাদেশ)",
    code: "bd",
    prefix: "+880",
    example: "1812 345678",
  },
  {
    name: "Barbados",
    code: "bb",
    prefix: "+1246",
    example: "250 1234",
  },
  {
    name: "Belarus (Беларусь)",
    code: "by",
    prefix: "+375",
    example: "29 491 19 11",
  },
  {
    name: "Belgium (België)",
    code: "be",
    prefix: "+32",
    example: "470 12 34 56",
  },
  {
    name: "Belize",
    code: "bz",
    prefix: "+501",
    example: "622 1234",
  },
  {
    name: "Benin (Bénin)",
    code: "bj",
    prefix: "+229",
    example: "90 01 12 34",
  },
  {
    name: "Bermuda",
    code: "bm",
    prefix: "+1441",
    example: "370 1234",
  },
  {
    name: "Bhutan (འབྲུག)",
    code: "bt",
    prefix: "+975",
    example: "17 12 34 56",
  },
  {
    name: "Bolivia",
    code: "bo",
    prefix: "+591",
    example: "71234567",
  },
  {
    name: "Bosnia and Herzegovina (Босна и Херцеговина)",
    code: "ba",
    prefix: "+387",
    example: "61 123 456",
  },
  {
    name: "Botswana",
    code: "bw",
    prefix: "+267",
    example: "71 123 456",
  },
  {
    name: "Brazil (Brasil)",
    code: "br",
    prefix: "+55",
    example: "11 96123 4567?8?",
  },
  {
    name: "British Indian Ocean Territory",
    code: "io",
    prefix: "+246",
    example: "380 1234",
  },
  {
    name: "British Virgin Islands",
    code: "vg",
    prefix: "+1284",
    example: "300 1234",
  },
  {
    name: "Brunei",
    code: "bn",
    prefix: "+673",
    example: "712 3456",
  },
  {
    name: "Bulgaria (България)",
    code: "bg",
    prefix: "+359",
    example: "48 123 4567?",
  },
  {
    name: "Burkina Faso",
    code: "bf",
    prefix: "+226",
    example: "70 12 34 56",
  },
  {
    name: "Burundi (Uburundi)",
    code: "bi",
    prefix: "+257",
    example: "79 56 12 34",
  },
  {
    name: "Cambodia (កម្ពុជា)",
    code: "kh",
    prefix: "+855",
    example: "91 234 567",
  },
  {
    name: "Cameroon (Cameroun)",
    code: "cm",
    prefix: "+237",
    example: "6 71 23 45 67",
  },
  {
    name: "Canada",
    code: "ca",
    prefix: "+1",
    example: "506 234 5678",
  },
  {
    name: "Cape Verde (Kabu Verdi)",
    code: "cv",
    prefix: "+238",
    example: "991 12 34",
  },
  {
    name: "Cayman Islands",
    code: "ky",
    prefix: "+1345",
    example: "323 1234",
  },
  {
    name: "Central African Republic (République centrafricaine)",
    code: "cf",
    prefix: "+236",
    example: "70 01 23 45",
  },
  {
    name: "Chad (Tchad)",
    code: "td",
    prefix: "+235",
    example: "63 01 23 45",
  },
  {
    name: "Chile",
    code: "cl",
    prefix: "+56",
    example: "2 2123 4567",
  },
  {
    name: "China (中国)",
    code: "cn",
    prefix: "+86",
    example: "131 2345 6789",
  },
  {
    name: "Christmas Island",
    code: "cx",
    prefix: "+61",
    example: "412 345 678",
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "cc",
    prefix: "+61",
    example: "412 345 678",
  },
  {
    name: "Colombia",
    code: "co",
    prefix: "+57",
    example: "321 1234567",
  },
  {
    name: "Comoros (\u202bجزر القمر\u202c\u200e)",
    code: "km",
    prefix: "+269",
    example: "321 23 45",
  },
  {
    name: "Congo (Kinshasa)",
    code: "cd",
    prefix: "+243",
    example: "12 345 6789",
  },
  {
    name: "Congo (Republic) (Congo-Brazzaville)",
    code: "cg",
    prefix: "+242",
    example: "06 123 4567",
  },
  {
    name: "Cook Islands",
    code: "ck",
    prefix: "+682",
    example: "71 234",
  },
  {
    name: "Costa Rica",
    code: "cr",
    prefix: "+506",
    example: "8312 3456",
  },
  {
    name: "Côte d’Ivoire",
    code: "ci",
    prefix: "+225",
    example: "01 23 45 67 8?9?",
  },
  {
    name: "Croatia (Hrvatska)",
    code: "hr",
    prefix: "+385",
    example: "92 123 4567",
  },
  {
    name: "Cuba",
    code: "cu",
    prefix: "+53",
    example: "5 1234567",
  },
  {
    name: "Curaçao",
    code: "cw",
    prefix: "+599",
    example: "9 518 1234",
  },
  {
    name: "Cyprus (Κύπρος)",
    code: "cy",
    prefix: "+357",
    example: "96 123456",
  },
  {
    name: "Czech Republic (Česká republika)",
    code: "cz",
    prefix: "+420",
    example: "601 123 456",
  },
  {
    name: "Denmark (Danmark)",
    code: "dk",
    prefix: "+45",
    example: "32 12 34 56",
  },
  {
    name: "Djibouti",
    code: "dj",
    prefix: "+253",
    example: "77 83 10 01",
  },
  {
    name: "Dominica",
    code: "dm",
    prefix: "+1767",
    example: "225 1234",
  },
  {
    name: "Dominican Republic (República Dominicana)",
    code: "do",
    prefix: "+1",
    example: "809 234 5678",
  },
  {
    name: "Ecuador",
    code: "ec",
    prefix: "+593",
    example: "99 123 4567",
  },
  {
    name: "Egypt (\u202bمصر\u202c\u200e)",
    code: "eg",
    prefix: "+20",
    example: "100 123 4567",
  },
  {
    name: "El Salvador",
    code: "sv",
    prefix: "+503",
    example: "7012 3456",
  },
  {
    name: "Equatorial Guinea (Guinea Ecuatorial)",
    code: "gq",
    prefix: "+240",
    example: "222 123 456",
  },
  {
    name: "Eritrea",
    code: "er",
    prefix: "+291",
    example: "7 123 456",
  },
  {
    name: "Estonia (Eesti)",
    code: "ee",
    prefix: "+372",
    example: "5123 4567",
  },
  {
    name: "Ethiopia",
    code: "et",
    prefix: "+251",
    example: "91 123 4567",
  },
  {
    name: "Falkland Islands (Islas Malvinas)",
    code: "fk",
    prefix: "+500",
    example: "51234",
  },
  {
    name: "Faroe Islands (Føroyar)",
    code: "fo",
    prefix: "+298",
    example: "211234",
  },
  {
    name: "Fiji",
    code: "fj",
    prefix: "+679",
    example: "701 2345",
  },
  {
    name: "Finland (Suomi)",
    code: "fi",
    prefix: "+358",
    example: "41 2345678",
  },
  {
    name: "France",
    code: "fr",
    prefix: "+33",
    example: "6 12 34 56 78",
  },
  {
    name: "French Polynesia (Polynésie française)",
    code: "pf",
    prefix: "+689",
    example: "87 12 34 56",
  },
  {
    name: "Gabon",
    code: "ga",
    prefix: "+241",
    example: "06 03 12 34",
  },
  {
    name: "Gambia",
    code: "gm",
    prefix: "+220",
    example: "301 2345",
  },
  {
    name: "Georgia (საქართველო)",
    code: "ge",
    prefix: "+995",
    example: "555 12 34 56",
  },
  {
    name: "Germany (Deutschland)",
    code: "de",
    prefix: "+49",
    example: "1512 3456789",
  },
  {
    name: "Ghana (Gaana)",
    code: "gh",
    prefix: "+233",
    example: "23 123 4567",
  },
  {
    name: "Gibraltar",
    code: "gi",
    prefix: "+350",
    example: "57123456",
  },
  {
    name: "Greece (Ελλάδα)",
    code: "gr",
    prefix: "+30",
    example: "691 234 5678",
  },
  {
    name: "Greenland (Kalaallit Nunaat)",
    code: "gl",
    prefix: "+299",
    example: "22 12 34",
  },
  {
    name: "Grenada",
    code: "gd",
    prefix: "+1473",
    example: "403 1234",
  },
  {
    name: "Guam",
    code: "gu",
    prefix: "+1671",
    example: "300 1234",
  },
  {
    name: "Guatemala",
    code: "gt",
    prefix: "+502",
    example: "5123 4567",
  },
  {
    name: "Guernsey",
    code: "gg",
    prefix: "+44",
    example: "7781 123456",
  },
  {
    name: "Guinea (Guinée)",
    code: "gn",
    prefix: "+224",
    example: "601 12 34 56",
  },
  {
    name: "Guinea-Bissau (Guiné Bissau)",
    code: "gw",
    prefix: "+245",
    example: "955 012 345",
  },
  {
    name: "Haiti",
    code: "ht",
    prefix: "+509",
    example: "34 10 1234",
  },
  {
    name: "Honduras",
    code: "hn",
    prefix: "+504",
    example: "9123 4567",
  },
  {
    name: "Hong Kong (香港)",
    code: "hk",
    prefix: "+852",
    example: "5123 4567",
  },
  {
    name: "Hungary (Magyarország)",
    code: "hu",
    prefix: "+36",
    example: "20 123 4567",
  },
  {
    name: "Iceland (Ísland)",
    code: "is",
    prefix: "+354",
    example: "611 1234",
  },
  {
    name: "India (भारत)",
    code: "in",
    prefix: "+91",
    example: "81234 56789",
  },
  {
    name: "Indonesia",
    code: "id",
    prefix: "+62",
    example: "812 345 678 9?0?",
  },
  {
    name: "Iran (\u202bایران\u202c\u200e)",
    code: "ir",
    prefix: "+98",
    example: "912 345 6789",
  },
  {
    name: "Iraq (\u202bالعراق\u202c\u200e)",
    code: "iq",
    prefix: "+964",
    example: "791 234 5678",
  },
  {
    name: "Ireland",
    code: "ie",
    prefix: "+353",
    example: "85 012 3456",
  },
  {
    name: "Isle of Man",
    code: "im",
    prefix: "+44",
    example: "7924 123456",
  },
  {
    name: "Israel (\u202bישראל\u202c\u200e)",
    code: "il",
    prefix: "+972",
    example: "50 234 5678",
  },
  {
    name: "Italy (Italia)",
    code: "it",
    prefix: "+39",
    example: "312 345 6789",
  },
  {
    name: "Jamaica",
    code: "jm",
    prefix: "+1",
    example: "876 210 1234",
  },
  {
    name: "Japan (日本)",
    code: "jp",
    prefix: "+81",
    example: "90 1234 5678",
  },
  {
    name: "Jersey",
    code: "je",
    prefix: "+44",
    example: "7797 712345",
  },
  {
    name: "Jordan (\u202bالأردن\u202c\u200e)",
    code: "jo",
    prefix: "+962",
    example: "7 9012 3456",
  },
  {
    name: "Kazakhstan (Казахстан)",
    code: "kz",
    prefix: "+7",
    example: "771 000 9998",
  },
  {
    name: "Kenya",
    code: "ke",
    prefix: "+254",
    example: "712 123456",
  },
  {
    name: "Kosovo",
    code: "xk",
    prefix: "+383",
    example: "43 201 234",
  },
  {
    name: "Kuwait (\u202bالكويت\u202c\u200e)",
    code: "kw",
    prefix: "+965",
    example: "500 12345",
  },
  {
    name: "Kyrgyzstan (Кыргызстан)",
    code: "kg",
    prefix: "+996",
    example: "700 123 456",
  },
  {
    name: "Laos (ລາວ)",
    code: "la",
    prefix: "+856",
    example: "20 23 123 456",
  },
  {
    name: "Latvia (Latvija)",
    code: "lv",
    prefix: "+371",
    example: "21 234 567",
  },
  {
    name: "Lebanon (\u202bلبنان\u202c\u200e)",
    code: "lb",
    prefix: "+961",
    example: "71 123 456",
  },
  {
    name: "Lesotho",
    code: "ls",
    prefix: "+266",
    example: "5012 3456",
  },
  {
    name: "Liberia",
    code: "lr",
    prefix: "+231",
    example: "77 012 3456",
  },
  {
    name: "Libya (\u202bليبيا\u202c\u200e)",
    code: "ly",
    prefix: "+218",
    example: "91 2345678",
  },
  {
    name: "Liechtenstein",
    code: "li",
    prefix: "+423",
    example: "660 234 567",
  },
  {
    name: "Lithuania (Lietuva)",
    code: "lt",
    prefix: "+370",
    example: "612 34567",
  },
  {
    name: "Luxembourg",
    code: "lu",
    prefix: "+352",
    example: "628 123 456",
  },
  {
    name: "Macedonia (FYROM) (Македонија)",
    code: "mk",
    prefix: "+389",
    example: "72 345 678",
  },
  {
    name: "Madagascar (Madagasikara)",
    code: "mg",
    prefix: "+261",
    example: "32 12 345 67",
  },
  {
    name: "Malawi",
    code: "mw",
    prefix: "+265",
    example: "991 23 45 67",
  },
  {
    name: "Malaysia",
    code: "my",
    prefix: "+60",
    example: "12 345 6789",
  },
  {
    name: "Maldives",
    code: "mv",
    prefix: "+960",
    example: "771 2345",
  },
  {
    name: "Mali",
    code: "ml",
    prefix: "+223",
    example: "65 01 23 45",
  },
  {
    name: "Malta",
    code: "mt",
    prefix: "+356",
    example: "9696 1234",
  },
  {
    name: "Marshall Islands",
    code: "mh",
    prefix: "+692",
    example: "235 1234",
  },
  {
    name: "Mauritania (\u202bموريتانيا\u202c\u200e)",
    code: "mr",
    prefix: "+222",
    example: "22 12 34 56",
  },
  {
    name: "Mauritius (Moris)",
    code: "mu",
    prefix: "+230",
    example: "5251 2345",
  },
  {
    name: "Mexico (México)",
    code: "mx",
    prefix: "+52",
    example: "1 222 123 4567?",
  },
  {
    name: "Micronesia",
    code: "fm",
    prefix: "+691",
    example: "350 1234",
  },
  {
    name: "Moldova (Republica Moldova)",
    code: "md",
    prefix: "+373",
    example: "621 12 345",
  },
  {
    name: "Monaco",
    code: "mc",
    prefix: "+377",
    example: "6 12 34 56 78",
  },
  {
    name: "Mongolia (Монгол)",
    code: "mn",
    prefix: "+976",
    example: "8812 3456",
  },
  {
    name: "Montenegro (Crna Gora)",
    code: "me",
    prefix: "+382",
    example: "67 622 901",
  },
  {
    name: "Montserrat",
    code: "ms",
    prefix: "+1664",
    example: "492 3456",
  },
  {
    name: "Morocco (\u202bالمغرب\u202c\u200e)",
    code: "ma",
    prefix: "+212",
    example: "650 123456",
  },
  {
    name: "Mozambique (Moçambique)",
    code: "mz",
    prefix: "+258",
    example: "82 123 4567",
  },
  {
    name: "Myanmar (Burma) (မြန်မာ)",
    code: "mm",
    prefix: "+95",
    example: "9 212 3456",
  },
  {
    name: "Namibia (Namibië)",
    code: "na",
    prefix: "+264",
    example: "81 123 4567",
  },
  {
    name: "Nauru",
    code: "nr",
    prefix: "+674",
    example: "555 1234",
  },
  {
    name: "Nepal (नेपाल)",
    code: "np",
    prefix: "+977",
    example: "984 1234567",
  },
  {
    name: "Netherlands (Nederland)",
    code: "nl",
    prefix: "+31",
    example: "6 12345678",
  },
  {
    name: "New Zealand",
    code: "nz",
    prefix: "+64",
    example: "21 123 4567",
  },
  {
    name: "Nicaragua",
    code: "ni",
    prefix: "+505",
    example: "8123 4567",
  },
  {
    name: "Niger (Nijar)",
    code: "ne",
    prefix: "+227",
    example: "93 12 34 56",
  },
  {
    name: "Nigeria",
    code: "ng",
    prefix: "+234",
    example: "802 123 4567",
  },
  {
    name: "Niue",
    code: "nu",
    prefix: "+683",
    example: "888 4012",
  },
  {
    name: "North Korea (조선 민주주의 인민 공화국)",
    code: "kp",
    prefix: "+850",
    example: "192 123 4567",
  },
  {
    name: "Northern Mariana Islands",
    code: "mp",
    prefix: "+1670",
    example: "234 5678",
  },
  {
    name: "Norway (Norge)",
    code: "no",
    prefix: "+47",
    example: "406 12 345",
  },
  {
    name: "Oman (\u202bعُمان\u202c\u200e)",
    code: "om",
    prefix: "+968",
    example: "9212 3456",
  },
  {
    name: "Pakistan (\u202bپاکستان\u202c\u200e)",
    code: "pk",
    prefix: "+92",
    example: "301 2345678",
  },
  {
    name: "Palau",
    code: "pw",
    prefix: "+680",
    example: "620 1234",
  },
  {
    name: "Palestine (\u202bفلسطين\u202c\u200e)",
    code: "ps",
    prefix: "+970",
    example: "599 123 456",
  },
  {
    name: "Panama (Panamá)",
    code: "pa",
    prefix: "+507",
    example: "6123 4567",
  },
  {
    name: "Papua New Guinea",
    code: "pg",
    prefix: "+675",
    example: "7012 3456",
  },
  {
    name: "Paraguay",
    code: "py",
    prefix: "+595",
    example: "961 456789",
  },
  {
    name: "Peru (Perú)",
    code: "pe",
    prefix: "+51",
    example: "912 345 678",
  },
  {
    name: "Philippines",
    code: "ph",
    prefix: "+63",
    example: "905 123 4567",
  },
  {
    name: "Poland (Polska)",
    code: "pl",
    prefix: "+48",
    example: "512 345 678",
  },
  {
    name: "Portugal",
    code: "pt",
    prefix: "+351",
    example: "912 345 678",
  },
  {
    name: "Puerto Rico",
    code: "pr",
    prefix: "+1",
    example: "787 234 5678",
  },
  {
    name: "Qatar (\u202bقطر\u202c\u200e)",
    code: "qa",
    prefix: "+974",
    example: "3312 3456",
  },
  {
    name: "Romania (România)",
    code: "ro",
    prefix: "+40",
    example: "712 034 567",
  },
  {
    name: "Rwanda",
    code: "rw",
    prefix: "+250",
    example: "720 123 456",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "kn",
    prefix: "+1869",
    example: "765 2917",
  },
  {
    name: "Samoa",
    code: "ws",
    prefix: "+685",
    example: "72 12345",
  },
  {
    name: "San Marino",
    code: "sm",
    prefix: "+378",
    example: "66 66 12 12",
  },
  {
    name: "São Tomé and Príncipe (São Tomé e Príncipe)",
    code: "st",
    prefix: "+239",
    example: "981 2345",
  },
  {
    name: "Saudi Arabia (\u202bالمملكة العربية السعودية\u202c\u200e)",
    code: "sa",
    prefix: "+966",
    example: "51 234 5678",
  },
  {
    name: "Senegal (Sénégal)",
    code: "sn",
    prefix: "+221",
    example: "70 123 45 67",
  },
  {
    name: "Serbia (Србија)",
    code: "rs",
    prefix: "+381",
    example: "60 1234567",
  },
  {
    name: "Seychelles",
    code: "sc",
    prefix: "+248",
    example: "2 510 123",
  },
  {
    name: "Sierra Leone",
    code: "sl",
    prefix: "+232",
    example: "25 123456",
  },
  {
    name: "Singapore",
    code: "sg",
    prefix: "+65",
    example: "8123 4567",
  },
  {
    name: "Sint Maarten",
    code: "sx",
    prefix: "+1721",
    example: "520 5678",
  },
  {
    name: "Slovakia (Slovensko)",
    code: "sk",
    prefix: "+421",
    example: "912 123 456",
  },
  {
    name: "Slovenia (Slovenija)",
    code: "si",
    prefix: "+386",
    example: "31 234 567",
  },
  {
    name: "Solomon Islands",
    code: "sb",
    prefix: "+677",
    example: "74 21234",
  },
  {
    name: "Somalia (Soomaaliya)",
    code: "so",
    prefix: "+252",
    example: "977 123 456",
  },
  {
    name: "South Africa",
    code: "za",
    prefix: "+27",
    example: "71 123 4567",
  },
  {
    name: "South Korea (대한민국)",
    code: "kr",
    prefix: "+82",
    example: "10 0000 0000",
  },
  {
    name: "South Sudan (\u202bجنوب السودان\u202c\u200e)",
    code: "ss",
    prefix: "+211",
    example: "977 123 456",
  },
  {
    name: "Spain (España)",
    code: "es",
    prefix: "+34",
    example: "612 34 56 78",
  },
  {
    name: "Sri Lanka (ශ්\u200dරී ලංකාව)",
    code: "lk",
    prefix: "+94",
    example: "71 234 5678",
  },
  {
    name: "Sudan (\u202bالسودان\u202c\u200e)",
    code: "sd",
    prefix: "+249",
    example: "91 123 1234",
  },
  {
    name: "Sweden (Sverige)",
    code: "se",
    prefix: "+46",
    example: "70 123 45 67",
  },
  {
    name: "Switzerland (Schweiz)",
    code: "ch",
    prefix: "+41",
    example: "78 123 45 67",
  },
  {
    name: "Syria (\u202bسوريا\u202c\u200e)",
    code: "sy",
    prefix: "+963",
    example: "944 567 890",
  },
  {
    name: "Taiwan (台灣)",
    code: "tw",
    prefix: "+886",
    example: "912 345 678",
  },
  {
    name: "Tajikistan",
    code: "tj",
    prefix: "+992",
    example: "917 12 3456",
  },
  {
    name: "Tanzania",
    code: "tz",
    prefix: "+255",
    example: "621 234 567",
  },
  {
    name: "Thailand (ไทย)",
    code: "th",
    prefix: "+66",
    example: "81 234 5678",
  },
  {
    name: "Timor-Leste",
    code: "tl",
    prefix: "+670",
    example: "7721 2345",
  },
  {
    name: "Togo",
    code: "tg",
    prefix: "+228",
    example: "90 11 23 45",
  },
  {
    name: "Tokelau",
    code: "tk",
    prefix: "+690",
    example: "7290",
  },
  {
    name: "Tonga",
    code: "to",
    prefix: "+676",
    example: "771 5123",
  },
  {
    name: "Trinidad and Tobago",
    code: "tt",
    prefix: "+1868",
    example: "291 1234",
  },
  {
    name: "Tunisia (\u202bتونس\u202c\u200e)",
    code: "tn",
    prefix: "+216",
    example: "20 123 456",
  },
  {
    name: "Turkey (Türkiye)",
    code: "tr",
    prefix: "+90",
    example: "501 234 56 78",
  },
  {
    name: "Turkmenistan",
    code: "tm",
    prefix: "+993",
    example: "66 123456",
  },
  {
    name: "Uganda",
    code: "ug",
    prefix: "+256",
    example: "712 345678",
  },
  {
    name: "United Arab Emirates (\u202bالإمارات العربية المتحدة\u202c\u200e)",
    code: "ae",
    prefix: "+971",
    example: "50 123 4567",
  },
  {
    name: "United Kingdom",
    code: "gb",
    prefix: "+44",
    example: "7400 123456",
  },
  {
    name: "United States",
    code: "us",
    prefix: "+1",
    example: "201 555 0123",
  },
  {
    name: "Uruguay",
    code: "uy",
    prefix: "+598",
    example: "94 231 234",
  },
  {
    name: "Uzbekistan (Oʻzbekiston)",
    code: "uz",
    prefix: "+998",
    example: "91 234 56 78",
  },
  {
    name: "Vanuatu",
    code: "vu",
    prefix: "+678",
    example: "591 2345",
  },
  {
    name: "Vatican City (Città del Vaticano)",
    code: "va",
    prefix: "+39",
    example: "312 345 6789",
  },
  {
    name: "Venezuela",
    code: "ve",
    prefix: "+58",
    example: "412 1234567",
  },
  {
    name: "Vietnam (Việt Nam)",
    code: "vn",
    prefix: "+84",
    example: "91 234 56 78",
  },
  {
    name: "Yemen (\u202bاليمن\u202c\u200e)",
    code: "ye",
    prefix: "+967",
    example: "712 345 678",
  },
  {
    name: "Zambia",
    code: "zm",
    prefix: "+260",
    example: "95 5123456",
  },
];

export const suggestedAmounts: Readonly<number[]> = [
  5, 10, 25, 50, 75, 100, 150, 250, 500, 1000, 3000, 5000, 10000, 15000, 25000, 50000, 150000, 250000,
];
