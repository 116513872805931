<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import type { CryptoApiResponse, Deposit } from "@/entities/deposit";
import { bridgeService } from "@/shared/services";
import { Button, Tooltip, Typography } from "@/shared/ui";

interface Props {
  response: Deposit["apiResponse"] & { data: CryptoApiResponse };
}

defineProps<Props>();

const { t } = useI18n();

const isCopyTooltipShown = ref(false);

const changeIsCopyTooltipShown = (value: boolean) => {
  isCopyTooltipShown.value = value;
};

const copyAddress = (response: Deposit["apiResponse"] & { data: CryptoApiResponse }) => {
  bridgeService.notifyNative({
    copiedMessage: response.data.depositAddress,
    messageType: "onCopyToBuffer",
  });
  changeIsCopyTooltipShown(true);
};
</script>

<template>
  <div class="flex gap-3">
    <Tooltip
      class="w-full"
      :duration="3_000"
      :is-open="isCopyTooltipShown"
      placement="top-start"
      @close="changeIsCopyTooltipShown(false)"
    >
      <template #default>
        <Button
          color="secondary"
          full-width
          :is-disabled="isCopyTooltipShown"
          @click="copyAddress(response)"
        >
          {{ t("button.copy") }}
        </Button>
      </template>
      <template #content>
        <Typography
          class="max-w-50"
          color="auto"
          size="sm"
        >
          {{ t("deposit.crypto.address.copy", { crypto: response.data.cryptoName }) }}
        </Typography>
      </template>
    </Tooltip>
    <div class="w-full">
      <Button
        color="auto"
        full-width
        variant="bordered"
        @click="bridgeService.notifyNative({ sharedMessage: response.data.depositURL, messageType: 'onShare' })"
      >
        {{ t("button.share") }}
      </Button>
    </div>
  </div>
</template>
